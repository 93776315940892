import styled, { css } from 'styled-components';

import { Button, Popup as PopupComponent } from 'polli-commons-fe/components';

export const Popup = styled(PopupComponent)`
  &-content {
    ${({ theme }) => css`
      background: ${theme.colors.black};
      border: ${theme.config.input.border};
    `}
  }
`;

export const NotRecommendedStatusButton = styled(Button)`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const NotRecommendedLabel = styled.span`
  color: ${({ theme }) => theme.colors.blue.primary.main};
`;
