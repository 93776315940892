const URL = {
  EXCHANGE_RATES: 'exchange-rates',
  CHAIN_CURRENCY_DATA: 'chains-data/currencies/{currency}',
  COSMOS_VALIDATORS_INCOME_HISTORY:
    'multi-validators/income-history/wallets/{walletAddress}',
  COSMOS_VALIDATOR_ICON:
    'https://raw.githubusercontent.com/cosmostation/chainlist/main/chain/{chainType}/moniker/{validatorAddress}.png',
};

export { URL };
