import { PropsWithChildren } from 'react';
import { Formik, FormikValues } from 'formik';

import { Page } from '../page';

import * as Styles from './styles';
import { AuthPageProps, BaseAuthPageProps } from './types';

export const BaseAuthPage = ({
  title,
  children,
  width = '520px',
  ...props
}: PropsWithChildren<BaseAuthPageProps>) => {
  return (
    <Page hasLogo {...props}>
      <Styles.Background $width={width}>
        <Styles.Title>{title}</Styles.Title>

        {children}
      </Styles.Background>
    </Page>
  );
};

export const AuthPage = <T extends FormikValues>(props: AuthPageProps<T>) => {
  const {
    title,
    children,
    showBackground,
    validateOnBlur = false,
    childrenContainerStyle,
    validateOnChange = false,
    enableReinitialize = false,
    ...formikProps
  } = props;

  return (
    <BaseAuthPage
      title={title}
      showBackground={showBackground}
      childrenContainerStyle={childrenContainerStyle}
    >
      <Formik<T>
        validateOnBlur={validateOnBlur}
        validateOnChange={validateOnChange}
        enableReinitialize={enableReinitialize}
        {...formikProps}
        children={(childrenProps) => {
          const render =
            typeof children === 'function' ? children(childrenProps) : children;
          return <Styles.FormikForm noValidate>{render}</Styles.FormikForm>;
        }}
      />
    </BaseAuthPage>
  );
};
