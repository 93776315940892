import styled, { css } from 'styled-components';

import { Icon } from '../icons';
import { PageBackground } from '../page-background';

export const Component = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Background = styled(PageBackground)`
  z-index: -20;
`;

export const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) =>
    theme.responsive.isMobile
      ? css`
          gap: 48px;
          padding: 16px;
        `
      : css`
          min-height: 100%;
          gap: 40px;
          padding: 24px;
        `}
`;

export const Logo = styled(Icon.LogoFull)`
  ${({ theme }) => css`
    ${theme.responsive.isMobile
      ? css`
          width: 70%;
        `
      : css`
          width: 500px;
        `}
  `}
`;
