import { Form } from 'formik';
import styled, { css } from 'styled-components';

import { subTitle } from '../../styles';
import { TransparentBackground } from '../transparent-background';

export const Title = styled.h1`
  text-align: center;

  ${({ theme }) => css`
    ${theme.responsive.isMobile
      ? css`
          margin-bottom: 24px;
          ${subTitle};
        `
      : css`
          margin-bottom: 40px;
        `}
  `}
`;

export const Background = styled(TransparentBackground)<{ $width: string }>`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ theme, $width }) => css`
    width: ${theme.responsive.isTabletUp ? $width : '90%'};
  `}
`;

export const FormikForm = styled(Form)`
  width: 100%;
`;
