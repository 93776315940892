import styled, { css } from 'styled-components';

import { Flex } from '../styles';
import { gradientBorder } from '../../styles/mixins';

export const Container = styled(Flex)`
  ${gradientBorder};
  flex-direction: column;
  border-radius: 28px;
  overflow: hidden;

  ${({ theme }) => css`
    background: ${theme.config.popup.background.main};

    &::before {
      background: ${theme.config.walletsSidebar.walletCard.background};
    }

    ${theme.responsive.isMobile
      ? css`
          padding: 16px;
        `
      : css`
          padding: 24px;
        `}
  `}
`;

const canvasStyle = css`
  position: absolute;
  z-index: -1;
`;

export const RightSideStarsCanvas = styled.canvas`
  ${canvasStyle};
  right: 0;
`;

export const TopStarsCanvas = styled.canvas`
  ${canvasStyle};
  top: 0;
  left: 15%;
`;
