import styled, { css } from 'styled-components';

import { Flex } from '../../styles';
import { TableRowProps } from '../types';
import { handleTableRowTemplate } from '../../../styles/mixins';

export const TableRowContainer = styled.div<
  {
    $gap: number;
    $padding: string;
    $template: string;
  } & Pick<TableRowProps, 'styleType'>
>`
  display: grid;
  border-radius: 28px;
  align-items: center;

  ${handleTableRowTemplate};

  ${({ $gap, theme, onClick, $padding, styleType }) => css`
    grid-gap: ${$gap}px;
    padding: ${$padding};

    ${onClick &&
    css`
      cursor: pointer;

      &:hover {
        background: ${styleType === 'lava'
          ? theme.config.lavaTableRow.background
          : theme.config.input.background.hover};
      }

      &:active {
        background: ${styleType === 'lava'
          ? theme.config.lavaTableRow.background
          : theme.config.input.background.active};
      }
    `}

    background: ${styleType === 'lava'
      ? theme.config.lavaTableRow.background
      : theme.config.tableRow.background};
    box-shadow: ${theme.config.tableRow.boxShadow};
    backdrop-filter: ${theme.config.tableRow.backdropFilter};
    -webkit-backdrop-filter: ${theme.config.tableRow.backdropFilter};
  `}
`;

export const ItemContainer = styled(Flex)`
  min-width: 0;
  flex-direction: column;
`;
