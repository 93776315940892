import {
  useMemo,
  useState,
  useEffect,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react';

export interface HeaderPageProps {
  pageName?: string;
  showBackButton?: boolean;
}

interface HeaderContextProps {
  pageState: HeaderPageProps;
  setPageState: (data: HeaderPageProps) => void;
}

export const HeaderContext = createContext<HeaderContextProps>({
  pageState: {},
  setPageState: () => {},
});

export const HeaderContextProvider = ({
  children,
}: PropsWithChildren<HeaderPageProps>) => {
  const [pageState, setPageState] = useState({});

  const value = useMemo(
    () => ({
      pageState,
      setPageState,
    }),
    [pageState, setPageState]
  );

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export const useHeader = ({ pageName, showBackButton }: HeaderPageProps) => {
  const { setPageState } = useContext(HeaderContext);
  useEffect(() => {
    setPageState({ pageName, showBackButton });
  }, [showBackButton, pageName, setPageState]);
};
