import styled from 'styled-components';

export const Container = styled.div`
  word-break: break-word;

  > * {
    line-height: 24px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
`;
