import { DefaultTheme } from 'styled-components';

import { FONTS } from '../fonts';
import zLayerOrder from '../z-layer-order';

import { LIGHT_COLORS } from './colors';
import { LIGHT_CONFIG } from './config';

const LIGHT_THEME: DefaultTheme = Object.freeze({
  zLayerOrder,
  fonts: FONTS,
  isDarkTheme: false,
  colors: LIGHT_COLORS,
  config: LIGHT_CONFIG,
  responsive: {
    isMobile: false,
    isTablet: false,
    isDesktop: true,
    isTabletUp: false,
  },
});

export { LIGHT_THEME };
