import styled, { css } from 'styled-components';

import { modalBackground } from '../../styles/mixins';

export const Container = styled.div<{
  $isFullScreen: boolean;
  $absolutePosition: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ $isFullScreen, $absolutePosition }) => css`
    ${$isFullScreen &&
    css`
      top: 0;
      left: 0;
      z-index: ${({ theme }) => theme.zLayerOrder.loader};
      position: fixed;
      ${modalBackground};
    `}

    ${$absolutePosition &&
    css`
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: ${({ theme }) => theme.zLayerOrder.loader};
      position: absolute;
      ${modalBackground};
    `}
  `}

  display: flex;
  align-items: center;
  justify-content: center;
`;
