import { useTheme } from 'styled-components';

import { Icon } from '../icons';
import backgroundImage from '../../assets/images/background-image.png';

import * as Styles from './styles';
import { PageBackgroundProps } from './types';

export const PageBackground = ({
  children,
  className,
}: PageBackgroundProps) => {
  const theme = useTheme();

  return (
    <Styles.Component className={className}>
      <Styles.BackgroundImage alt="background" src={backgroundImage} />

      <Styles.Background>
        {theme.isDarkTheme ? <Icon.BackgroundDark /> : <Icon.BackgroundLight />}
      </Styles.Background>

      {children}
    </Styles.Component>
  );
};
