import { ethers } from 'ethers';

import { formatNumber } from 'polli-commons-fe/utils/helpers';
import { TransactionActivityProps } from 'polli-commons-fe/components/transaction-activity';
import {
  AmountResponse,
  CosmosChainType,
  AmountResponseWithUsd,
} from 'polli-commons-fe/types';
import {
  addCurrencyLabel,
  currencyDefaultMinimalValue,
  convertMicroUnitToUnitPrecise,
} from 'polli-commons-fe/utils/currencies';

export const weiToEther = (wei: string, shouldCheckMinimalValue = true) => {
  if (wei === '0') return wei;

  const eth = ethers.formatEther(wei);

  if (
    +eth < currencyDefaultMinimalValue &&
    +eth > 0 &&
    shouldCheckMinimalValue
  ) {
    return `< ${currencyDefaultMinimalValue}`;
  }

  return formatNumber(eth);
};

export const gweiToEther = (gwei: string, shouldCheckMinimalValue = true) => {
  if (gwei === '0') return gwei;

  const gweiInWei = ethers.parseUnits(gwei, 9);

  if (
    shouldCheckMinimalValue &&
    gweiInWei < currencyDefaultMinimalValue &&
    gweiInWei > 0
  ) {
    return `< ${currencyDefaultMinimalValue}`;
  }

  return formatNumber(ethers.formatEther(gweiInWei));
};

export const formatEthBalance = (rawBalance?: string | bigint) =>
  addCurrencyLabel(
    rawBalance ? formatNumber(parseInt(rawBalance.toString()) / 1e18) : '0',
    'ETHEREUM'
  );

export const convertCosmosMicroUnitToUnit = (
  value: string,
  shouldCheckMinimalValue = true
) => {
  if (value === '0' || !value) return '0';

  const divided = +value / 1_000_000;

  if (shouldCheckMinimalValue && divided < currencyDefaultMinimalValue) {
    return `< ${currencyDefaultMinimalValue}`;
  }

  return String(formatNumber(divided));
};

export const formatCosmosUnit = (value: string) =>
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  }).format(+value);

export const ethTransactionActivityFormat: Partial<TransactionActivityProps> = {
  tooltipRewardValueFormatter: (gwei) =>
    addCurrencyLabel((+gweiToEther(gwei)).toFixed(3), 'ETHEREUM'),
  rewardYAxisProps: {
    tickFormatter: (gwei) =>
      addCurrencyLabel(+gweiToEther(String(gwei)), 'ETHEREUM'),
  },
};

export const cosmosTransactionActivityCurrencyFormat =
  (chainType: CosmosChainType) => (value: string) =>
    addCurrencyLabel(convertMicroUnitToUnitPrecise(value, 2), chainType);

export const cosmosTransactionActivityFormat = (
  chainType: CosmosChainType
): Partial<TransactionActivityProps> => ({
  tooltipRewardValueFormatter:
    cosmosTransactionActivityCurrencyFormat(chainType),
  rewardYAxisProps: {
    tickFormatter: cosmosTransactionActivityCurrencyFormat(chainType),
  },
});

export const groupAmountsByCurrencies = ({
  data,
  mainCurrency,
}: {
  data: AmountResponseWithUsd[];
  mainCurrency: AmountResponse['currency'];
}) => {
  const result: AmountResponse[] = [];

  const mainCurrencyData = data.filter(
    (item) => item.amount.currency === mainCurrency
  );

  if (mainCurrencyData.length) {
    result.push(
      mainCurrencyData.reduce(
        (res, item) => {
          res.value = String(+res.value + +item.amount.value);
          return res;
        },
        { value: '0', currency: mainCurrency }
      )
    );
  }

  const filteredData = data.filter(
    (item) => item.amount.currency !== mainCurrency
  );

  for (const item of filteredData) {
    const index = result.findIndex((i) => i.currency === item.amount.currency);

    if (index === -1) {
      result.push({ ...item.amount });
    } else {
      result[index].value = String(+result[index].value + +item.amount.value);
    }
  }

  return result;
};
