import styled, { css } from 'styled-components';

import { Flex, Icon } from 'polli-commons-fe/components';

export const Container = styled(Flex)`
  padding: 16px;
  gap: 8px;
  border-radius: 24px;

  ${({ theme }) => css`
    color: ${theme.colors.red.additional.main};
    border: 1px solid ${theme.colors.red.additional.main};
    background: ${theme.config.alertMessage.background};
  `}
`;

export const AlertIcon = styled(Icon.AlertCircle)`
  path {
    fill: ${({ theme }) => theme.colors.red.additional.main};
  }
`;
