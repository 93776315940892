import { AnimatePresence } from 'framer-motion';

import * as Styles from './styles';
import { Loading } from './loading';
import { TransactionLoaderProps } from './types';
import { transactionLoaderMotionProps } from './config';

export const TransactionLoader = ({
  isLoading,
  ...props
}: TransactionLoaderProps) => {
  return (
    <AnimatePresence>
      {isLoading && (
        <Styles.Container {...transactionLoaderMotionProps}>
          <Loading {...props} />
        </Styles.Container>
      )}
    </AnimatePresence>
  );
};

TransactionLoader.Container = Styles.Container;
TransactionLoader.Loading = Loading;
