import styled from 'styled-components';
import { motion } from 'framer-motion';

import { TabsProps } from 'polli-commons-fe/components/tabs/types';

export const TabsList = styled.ul`
  gap: 24px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.config.table.tabs.border};
  overflow: auto;
`;

export const Tab = styled.li`
  cursor: pointer;
  padding: 12px 0 16px 0;
  position: relative;
`;

export const Underline = styled(motion.div)<Pick<TabsProps, 'styleType'>>`
  left: 0;
  right: 0;
  height: 4px;
  bottom: 0;
  position: absolute;
  border-radius: 24px;
  background: ${({ theme, styleType }) =>
    styleType === 'lava'
      ? theme.colors.lavaGradient.secondary
      : theme.colors.accent.primary.main};
`;
