import { ReactNode } from 'react';

import { Table } from 'polli-commons-fe/components/table';
import { TableProps } from 'polli-commons-fe/components/table/types';
import { useTableSort, useAnalyticsEventTracker } from 'polli-commons-fe/hooks';
import { SortableHeader } from 'polli-commons-fe/components/table/sortable-header';

export type SortableHeaderProps = {
  info?: string;
  label: ReactNode;
  accessor: string;
};

export interface TableWithSortingProps
  extends Omit<TableProps, 'headers'>,
    Omit<ReturnType<typeof useTableSort>, 'setSortState'> {
  headers: (ReactNode | SortableHeaderProps)[];
}

export const isSortableHeader = (
  props: ReactNode | SortableHeaderProps
): props is SortableHeaderProps =>
  Boolean(
    props &&
      typeof props === 'object' &&
      'accessor' in props &&
      'label' in props
  );

export const mapSortableHeaders = (
  headers: TableWithSortingProps['headers'],
  sortState: TableWithSortingProps['sortState'],
  handleSorting: TableWithSortingProps['handleSorting'],
  eventTracker?: ReturnType<typeof useAnalyticsEventTracker>,
  disabled?: boolean
) =>
  headers.map((header) => {
    if (isSortableHeader(header)) {
      return (
        <SortableHeader
          info={header.info}
          disabled={disabled}
          order={sortState[header.accessor]}
          onClick={() => {
            eventTracker?.({
              action: `Sort by ${
                header.label === 'string' ? header.label : header.accessor
              }`,
              label:
                typeof header.label === 'string'
                  ? header.label
                  : header.accessor,
            });

            handleSorting(header.accessor);
          }}
        >
          {header.label}
        </SortableHeader>
      );
    }

    return header;
  });

export const SortableTable = ({
  headers,
  sortState,
  handleSorting,
  ...props
}: TableWithSortingProps) => {
  const resultHeaders = mapSortableHeaders(headers, sortState, handleSorting);

  return <Table {...props} headers={resultHeaders} />;
};
