import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { compoundingApiQuery } from 'store/query';

import { CompoundingConfigResponse } from './types';

export const compoundingConfigApi = createApi({
  baseQuery: compoundingApiQuery,
  reducerPath: 'compoundingConfigApi',
  endpoints: (build) => ({
    getCompoundingConfig: build.query<CompoundingConfigResponse, void>({
      query: () => ({
        method: 'get',
        url: URL.COMPOUNDING_CONFIG,
      }),
    }),
  }),
});

export const { useGetCompoundingConfigQuery } = compoundingConfigApi;
