import { lazy } from 'react';

export const Login = lazy(() => import('./login'));
// export const SignUp = lazy(() => import('./sign-up'));
export const ResetPassword = lazy(() => import('./reset-password'));
export const ChangePassword = lazy(() => import('./change-password'));
export const EmailConfirmed = lazy(() => import('./email-confirmed'));
export const EmailVerification = lazy(() => import('./email-verification'));
export const TwoFactorVerification = lazy(
  () => import('./two-factor-verification')
);
export const TwoFactorVerificationConfirmSignUp = lazy(
  () => import('./two-factor-verification-confirm/sign-up')
);
