import * as math from 'mathjs';

export const shiftDigits = (
  num: string | number,
  places: number,
  decimalPlaces?: number
) => {
  const shiftedNum = math.bignumber(num).times(math.bignumber(10).pow(places));
  const roundedNum = math.round(shiftedNum, decimalPlaces || 6);
  return roundedNum.toString();
};
