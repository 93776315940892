import { createApi } from '@reduxjs/toolkit/query/react';

import { cosmosApiQuery } from 'polli-commons-fe/store/query';
import { determineChainTypeFromAddress } from 'polli-commons-fe/utils/helpers';

import { URL } from 'api/constants';

import { CosmosWallet, GetCosmosWalletsResponse } from './types';

export const cosmosWalletsTagTypes = ['cosmos-wallets'];

export const cosmosWalletsApi = createApi({
  baseQuery: cosmosApiQuery,
  tagTypes: cosmosWalletsTagTypes,
  reducerPath: 'cosmosWalletsApi',
  endpoints: (build) => ({
    saveCustomCosmosWallet: build.mutation({
      invalidatesTags: cosmosWalletsTagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.READONLY_WALLETS,
      }),
    }),
    saveCosmosWallet: build.mutation<void, string>({
      invalidatesTags: cosmosWalletsTagTypes,
      query: (address) => ({
        method: 'post',
        url: URL.WALLETS,
        data: { address },
      }),
    }),
    deleteCosmosCustomWallet: build.mutation<void, number>({
      invalidatesTags: cosmosWalletsTagTypes,
      query: (walletId) => ({
        method: 'delete',
        url: URL.READONLY_WALLET.replace('{walletId}', String(walletId)),
      }),
    }),
    getCosmosWallets: build.query<CosmosWallet[], void>({
      providesTags: cosmosWalletsTagTypes,
      query: () => ({
        method: 'get',
        url: URL.WALLETS,
      }),
      transformResponse: (response: GetCosmosWalletsResponse) =>
        response?.map((item) => ({
          ...item,
          wallet: {
            ...item.wallet,
            chainType: determineChainTypeFromAddress(item.wallet.address),
          },
        })),
    }),
  }),
});

export const {
  useGetCosmosWalletsQuery,
  useSaveCosmosWalletMutation,
  useSaveCustomCosmosWalletMutation,
  useDeleteCosmosCustomWalletMutation,
} = cosmosWalletsApi;

export const useCosmosReadonlyWallets = () =>
  useGetCosmosWalletsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      data: data?.filter(({ wallet }) => wallet.isReadOnly),
    }),
  });

export type { CosmosWallet };
