import styled, { css } from 'styled-components';

import { body, caption } from 'polli-commons-fe/styles';

import { Flex } from '../styles';

const canvas = css`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: visible;
`;

export const Container = styled.div`
  position: relative;
  overflow: visible;
  flex-shrink: 0;

  ${({ theme }) => css`
    width: ${theme.responsive.isMobile ? '250px' : '410px'};
    height: ${theme.responsive.isMobile ? '250px' : '410px'};
  `}
`;

export const CanvasBlurred = styled.canvas`
  ${canvas};
  filter: blur(21px);
`;

export const DotsBlurred = styled.canvas<{ $blur: number }>`
  ${canvas};
  ${({ $blur }) => css`
    filter: blur(${$blur}px);
  `}
`;

export const Canvas = styled.canvas`
  ${canvas};
`;

export const Overlay = styled.div`
  top: 0;
  bottom: 0;
  left: 50%;
  width: 98%;
  display: flex;
  position: absolute;
  text-align: center;
  align-items: center;
  pointer-events: none;
  flex-direction: column;
  justify-content: center;
  transform: translateX(-50%);
  background-size: contain;
`;

export const OverlayCenter = styled.div`
  position: absolute;
  width: 44%;
  height: 44%;
  border-radius: 50%;
  filter: blur(34px);
  ${({ theme }) => css`
    background: ${theme.config.background};
  `}
`;

export const TotalValueLabel = styled.h3`
  text-transform: uppercase;

  ${({ theme }) => css`
    color: ${theme.colors.grey.primary.main};

    ${theme.responsive.isMobile &&
    css`
      ${caption};
    `}
  `}
`;

export const TotalValue = styled.h2`
  ${({ theme }) =>
    theme.responsive.isMobile &&
    css`
      ${body};
    `}
`;

export const TotalValueContainer = styled.div`
  z-index: 1;
`;

export const TotalValuePlaceholder = styled.div`
  z-index: 1;
  width: 151px;
  height: 32px;
  border-radius: 16px;
  background: ${({ theme }) => theme.config.pieChart.valuePlaceholder};
`;

export const TotalValueTitlePlaceholder = styled.div`
  z-index: 1;
  width: 108px;
  height: 24px;
  border-radius: 16px;
  background-color: #7f867d;
`;

export const PlaceholdersContainer = styled(Flex)`
  gap: 8px;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
`;
