import { Button } from '../button';
import { Semibold } from '../styles';
import { getErrorMessage } from '../../utils/error';

import * as Styles from './styles';
import { RetryRequestProps } from './types';

export const RetryRequest = ({
  error,
  onRetry,
  children,
  defaultErrorMessage = 'Something went wrong',
  ...props
}: RetryRequestProps) => {
  return error ? (
    <Styles.Container {...props}>
      <Semibold>{getErrorMessage(error, defaultErrorMessage)}</Semibold>
      {onRetry && <Button text="Retry" width="300px" onClick={onRetry} />}
    </Styles.Container>
  ) : (
    children
  );
};
