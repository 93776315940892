import { childrenContainerGap } from './config';

export const generateTemplate = (count: number, value = 'minmax(100px, 1fr)') =>
  Array.from({ length: count })
    .map(() => value)
    .join(' ');

export const composeGridTemplateColumns = (
  config: { fr: number; repeat?: number }[]
) =>
  config
    .map(({ fr, repeat }) =>
      repeat
        ? `repeat(${repeat}, minmax(100px, ${fr}fr))`
        : `minmax(100px, ${fr}fr)`
    )
    .join(' ');

export const getTreeBranchesPositions = (
  singleChildHeight: number,
  childrenCount: number
) => {
  const halfOfHeight = singleChildHeight / 2;
  const positions: number[] = [];

  for (let i = 0; i < childrenCount; i++) {
    const previousPosition = positions[i - 1];

    if (previousPosition) {
      positions.push(
        previousPosition + singleChildHeight + childrenContainerGap
      );
    } else {
      positions.push(halfOfHeight);
    }
  }

  return positions;
};
