import { Popup } from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import styled, { css, keyframes } from 'styled-components';

import { PopupProps } from './types';

const anvil = keyframes`
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
`;

const overlayAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledPopup = styled(Popup)<{
  $width: PopupProps['width'];
  $height: PopupProps['height'];
  $maxWidth: PopupProps['maxWidth'];
}>`
  &-overlay {
    padding: 16px 0;
    overflow-y: auto;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    animation: ${overlayAnimation} 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9)
      forwards;

    ${({ theme }) => css`
      z-index: ${theme.zLayerOrder.popupOverlay} !important;
      background: ${theme.config.popup.overlay.background};
    `}
  }

  &-content {
    padding: 16px;
    border: none;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border-radius: 24px !important;
    -webkit-animation: ${anvil} 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    ${({ modal, theme, $width, $height, $maxWidth }) => css`
      z-index: ${theme.zLayerOrder.popupContent} !important;
      max-width: ${$maxWidth};
      ${$height && `height: ${$height}`};
      width: ${$width || 'auto'} !important;
      background: ${modal
        ? theme.config.popup.background.modal
        : theme.config.popup.background.main};
      box-shadow:
        0 8px 10px rgba(0, 0, 0, 0.04),
        0 3px 14px rgba(0, 0, 0, 0.04),
        0 4px 5px rgba(0, 0, 0, 0.04) !important;
    `}
  }
`;
