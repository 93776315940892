import axios from 'axios';

import { determineChainTypeFromAddress } from 'polli-commons-fe/utils/helpers';
import {
  ChainId,
  CosmosChainType,
  CosmosWalletProvider,
} from 'polli-commons-fe/types';

import { ChainDenom } from 'types';
import { ChainRestUrl } from 'types/data';

export const getWindowProvider = (provider: CosmosWalletProvider) =>
  provider === 'cosmostation'
    ? window[provider]?.providers.keplr
    : window[provider];

export const getCosmosWalletBalance = async (address: string) => {
  const chainType = determineChainTypeFromAddress(address);
  const restUrl = ChainRestUrl[chainType];

  const response = await axios.get<{
    balances: { denom: string; amount: string }[];
  }>(`${restUrl}/cosmos/bank/v1beta1/balances/${address}`);

  return response.data.balances.find(
    ({ denom }) => denom === ChainDenom[chainType]
  );
};

export const getCosmosWalletAddress = async ({
  chainType,
  providerName,
}: {
  chainType: CosmosChainType;
  providerName: CosmosWalletProvider;
}) => {
  const windowProvider = getWindowProvider(providerName);

  const chainId = ChainId[chainType];
  await windowProvider?.enable(chainId);
  const signerProvider = await windowProvider?.getOfflineSignerAuto(chainId);
  const accounts = await signerProvider?.getAccounts();
  const walletAddress = accounts?.[0].address ?? null;

  return walletAddress;
};
