const COSMOS_MESSAGE_TYPE_URL = {
  SEND: '/cosmos.bank.v1beta1.MsgSend',
  DELEGATE: '/cosmos.staking.v1beta1.MsgDelegate',
  UNDELEGATE: '/cosmos.staking.v1beta1.MsgUndelegate',
  BEGIN_REDELEGATE: '/cosmos.staking.v1beta1.MsgBeginRedelegate',
  REVOKE_ALLOWANCE: '/cosmos.feegrant.v1beta1.MsgRevokeAllowance',
  CANCEL_UNBONDING_DELEGATION:
    '/cosmos.staking.v1beta1.MsgCancelUnbondingDelegation',
  WITHDRAW_DELEGATOR_REWARD:
    '/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward',
};

export default COSMOS_MESSAGE_TYPE_URL;
