import { ReactNode, PropsWithChildren } from 'react';
import { MotionProps, AnimatePresence } from 'framer-motion';

import { Icon } from '../icons';
import { Button } from '../button';
import { isMobile } from '../../hooks';
import { motionOpacityProps } from '../../config/constants';

import * as Styles from './styles';

const Heading = ({
  title,
  onClose,
}: {
  title: ReactNode;
  onClose: () => void;
}) => {
  return (
    <Styles.Heading>
      {typeof title === 'string' ? <h2>{title}</h2> : title}

      <Button styleType="text" onClick={onClose} icon={<Icon.Close />} />
    </Styles.Heading>
  );
};

const Inner = ({
  width = '496px',
  withBorder = true,
  ...props
}: { width?: string; withBorder?: boolean } & MotionProps) => {
  const mobile = isMobile();

  return (
    <Styles.Inner
      exit={{ x: '100%' }}
      withBorder={withBorder}
      animate={{
        x: '0%',
      }}
      transition={{
        ease: 'easeInOut',
      }}
      initial={{ x: '100%', width: mobile ? '100%' : width }}
      {...props}
    />
  );
};

export const DrawerContainer = ({
  isOpen,
  children,
}: PropsWithChildren<{ isOpen: boolean }>) => {
  return (
    <>
      <AnimatePresence>
        {isOpen && <Styles.Overlay {...motionOpacityProps} />}
      </AnimatePresence>

      <AnimatePresence>{isOpen && children}</AnimatePresence>
    </>
  );
};

DrawerContainer.Inner = Inner;
DrawerContainer.Heading = Heading;
