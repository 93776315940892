import styled, { css } from 'styled-components';

import { TableViewType } from '../types';
import { caption } from '../../../styles/mixins/fonts';
import { handleTableRowTemplate } from '../../../styles/mixins';

export const HeadersContainer = styled.div<{
  $template: string;
  $viewType: TableViewType;
}>`
  display: grid;
  align-items: center;
  margin-bottom: 8px;

  ${handleTableRowTemplate};

  ${({ $viewType }) =>
    $viewType !== 'collapsed' &&
    css`
      padding-left: 24px;
      padding-right: 16px;
    `}
`;

export const Header = styled.div`
  text-align: left;
  ${caption};
  color: ${({ theme }) => theme.colors.grey.primary.main};
`;
