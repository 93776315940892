import { withRef } from '../../hoc';
import { EllipsisText } from '../ellipsis-text';
import { testIdAttribute } from '../../config/constants';
import { ThemeDependentIcon, AccentGradientIcon } from '../styles';

import * as Styles from './styles';
import { ButtonProps, ButtonStyleType } from './types';

export const Button = ({
  text,
  icon,
  width,
  testId,
  innerRef,
  isLoading,
  rightSlot,
  bold = true,
  grow = false,
  withEllipsis = true,
  styleType = 'primary',
  gradientBorder = false,
  nativeIconColor = false,
  iconColorParam = 'fill',
  ...props
}: ButtonProps) => {
  const commonProps = {
    $grow: grow,
    $bold: bold,
    $width: width,
    $styleType: styleType,
    [testIdAttribute]: testId,
    $gradientBorder: gradientBorder,
    $iconColorParam: iconColorParam,
    $nativeIconColor: nativeIconColor,
  };

  const getIconElement = () => {
    if (icon) {
      if (nativeIconColor) return icon;

      if (styleType === 'gradient')
        return <AccentGradientIcon>{icon}</AccentGradientIcon>;

      return (
        <ThemeDependentIcon $iconColorParam={iconColorParam}>
          {icon}
        </ThemeDependentIcon>
      );
    }
  };

  const textElement = withEllipsis ? (
    <Styles.Text as={EllipsisText}>{text}</Styles.Text>
  ) : (
    <Styles.Text>{text}</Styles.Text>
  );

  const children = (
    <>
      {isLoading && <Styles.Loader />}
      {getIconElement()}

      {text && (typeof text === 'string' ? textElement : text)}
      {rightSlot}
    </>
  );

  if ('to' in props) {
    return (
      <Styles.Link {...commonProps} $isInteractive {...props}>
        {children}
      </Styles.Link>
    );
  }

  const { type, disabled, ...buttonProps } = props;

  return (
    <Styles.Button
      {...commonProps}
      type={type ?? 'button'}
      disabled={disabled || isLoading}
      $isInteractive={Boolean(buttonProps.onClick) || type !== 'button'}
      {...buttonProps}
    >
      {children}
    </Styles.Button>
  );
};

export const ButtonWithRef = withRef(Button);

export type { ButtonProps, ButtonStyleType };
