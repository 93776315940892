import { BalanceRange, BalanceRangeOptions } from 'polli-commons-fe/types';

export const rangeLabel: Record<BalanceRange, string> = {
  LAST_YEAR: 'Last year',
  LAST_THIRTY_DAYS: 'Last 30 days',
  LAST_THREE_MONTH: 'Last 90 days',
};

export const rangeOptions = BalanceRangeOptions.map((value) => ({
  value,
  label: rangeLabel[value],
}));
