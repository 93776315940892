import { useMemo } from 'react';
import { css, useTheme } from 'styled-components';
import styled, { DefaultTheme } from 'styled-components';
import ReactSelect, {
  GroupBase,
  OptionProps,
  StylesConfig,
  ControlProps,
  CSSObjectWithLabel,
} from 'react-select';

import { Icon } from '../icons';
import { EllipsisText } from '../ellipsis-text';
import selectBg from '../../assets/bg-stars.svg';

import { SelectOption } from './types';

export const SelectArrowUp = styled(Icon.SelectArrow)<{ $open: boolean }>`
  transition: transform 300ms;

  ${({ $open }) =>
    $open &&
    css`
      transform: rotate(180deg);
    `}
`;

const controlBaseStyles = <
  Option = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  baseStyles: CSSObjectWithLabel,
  state: ControlProps<Option, IsMulti, Group>
): CSSObjectWithLabel => ({
  ...baseStyles,
  minHeight: 0,
  borderWidth: 0,
  borderRadius: 0,
  transition: 'none',
  position: 'relative',

  ...(state.isDisabled && {
    opacity: '0.6',
  }),
});

const optionBaseStyles = <
  Option = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  baseStyles: CSSObjectWithLabel,
  state: OptionProps<Option, IsMulti, Group>,
  theme: DefaultTheme
): CSSObjectWithLabel => ({
  ...baseStyles,
  padding: 16,
  color: theme.config.text.primary.main,

  ...(state.selectProps.styleType === 'secondary' && {
    position: 'relative',

    ':after': {
      left: 0,
      height: 1,
      bottom: 0,
      content: '""',
      width: '100%',
      position: 'absolute',
      background: theme.config.divider,
    },
  }),

  ...(state.isDisabled
    ? {
        opacity: 0.6,
      }
    : {
        ':active': {
          background: 'transparent',
        },
        ':hover': {
          background: theme.config.select.hover,
        },
      }),

  ...(state.isSelected
    ? {
        background: theme.config.select.selected,
        ':active': {
          background: theme.config.select.selected,
        },
      }
    : {
        background: 'transparent',
      }),
});

export const selectGradientBorderStyles = <
  T extends { isFocused: boolean; selectProps: { error?: string } },
>(
  theme: DefaultTheme,
  state: T
): CSSObjectWithLabel => ({
  '::before': {
    inset: 0,
    padding: 1,
    zIndex: -1,
    content: '""',
    position: 'absolute',
    borderRadius: 'inherit',
    maskComposite: 'exclude',
    WebkitMaskComposite: 'xor',
    WebkitMask:
      'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    background: state.selectProps.error
      ? theme.colors.red.primary.main
      : state.isFocused
        ? theme.colors.accent.primary.main
        : 'none',
  },
});

export const useStyles = <
  Option = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  styles?: StylesConfig<Option, IsMulti, Group>
) => {
  const theme = useTheme();

  return useMemo<StylesConfig<Option, IsMulti, Group>>(
    () => ({
      ...styles,
      dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        padding: 0,
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        color: theme.colors.grey.primary.main,
      }),
      multiValueLabel: (baseStyles) => ({
        ...baseStyles,
        padding: 0,
        color: theme.config.text.primary.main,
      }),
      input: (baseStyles) => ({
        ...baseStyles,
        margin: 0,
        padding: 0,
        color: theme.config.text.primary.main,
      }),
      multiValue: (baseStyles) => ({
        ...baseStyles,
        borderRadius: '8px',
        backgroundColor: theme.config.text.secondary.hover,
      }),
      clearIndicator: () => ({
        width: 24,
        height: 24,
        alignItems: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
      }),
      loadingIndicator: (baseStyles) => ({
        ...baseStyles,
        '> span': {
          width: '4px',
          height: '4px',
          marginLeft: '4px',
        },
      }),
      singleValue: (baseStyles, state) => ({
        ...baseStyles,
        color: theme.config.text.primary.main,
        ...styles?.singleValue?.(baseStyles, state),
        margin: 0,
      }),
      option: (baseStyles, state) => {
        if (styles?.option) {
          return styles.option(
            optionBaseStyles(baseStyles, state, theme),
            state
          );
        }

        return optionBaseStyles(baseStyles, state, theme);
      },
      valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        padding: 0,
        ...(state.selectProps.width && {
          width: state.selectProps.width,
        }),
        ...(state.selectProps.width && {
          width: state.selectProps.width,
        }),
      }),
      menu: (baseStyles, state) => {
        if (state.selectProps.styleType === 'primary') {
          return {
            ...baseStyles,
            margin: 0,
            borderRadius: 0,
            overflow: 'hidden',
            borderBottomLeftRadius: 32,
            borderBottomRightRadius: 32,
            backdropFilter: 'blur(40px)',
            boxShadow: theme.config.input.boxShadow.hover,
            background: theme.config.input.background.active,
            ...styles?.menu?.(baseStyles, state),
          };
        }

        if (state.selectProps.styleType === 'secondary') {
          return {
            ...baseStyles,
            marginTop: 24,
            borderRadius: 28,
            background: theme.colors.black,
            ...styles?.menu?.(baseStyles, state),
          };
        }

        return {};
      },
      menuList: (baseStyles, state) => {
        if (state.selectProps.styleType === 'primary') {
          return {
            ...baseStyles,
            padding: 0,
            position: 'static',
            ...styles?.menuList?.(baseStyles, state),
          };
        }

        if (state.selectProps.styleType === 'secondary') {
          return {
            ...baseStyles,

            padding: 0,
            borderRadius: 28,
            position: 'static',
            backgroundSize: 'auto',
            backgroundPosition: 'center',
            backgroundImage: `url(${selectBg})`,

            '::before': {
              inset: 0,
              padding: 1,
              zIndex: -1,
              content: '""',
              borderRadius: 28,
              position: 'absolute',
              WebkitMaskComposite: 'xor',
              background: theme.colors.accent.primary.main,
              WebkitMask:
                'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            },
            ...styles?.menuList?.(baseStyles, state),
          };
        }

        return baseStyles;
      },
      control: (baseStyles, state) => {
        if (state.selectProps.styleType === 'primary') {
          return {
            ...controlBaseStyles<Option, IsMulti, Group>(baseStyles, state),
            padding: 16,
            boxShadow: theme.config.input.boxShadow.main,
            backdropFilter: theme.config.input.backdropFilter,

            ...(state.isFocused
              ? {
                  background: theme.config.input.background.active,
                }
              : {
                  background: theme.config.input.background.main,

                  ':hover': {
                    boxShadow: theme.config.input.boxShadow.hover,
                    background: theme.config.input.background.hover,
                  },
                }),

            ...(state.menuIsOpen
              ? {
                  borderTopLeftRadius: 28,
                  borderTopRightRadius: 28,
                }
              : {
                  borderRadius: 28,
                }),

            ...(state.isDisabled && {
              opacity: '0.6',
            }),

            ...selectGradientBorderStyles(theme, state),
            ...styles?.control?.(baseStyles, state),
          };
        }

        if (state.selectProps.styleType === 'secondary') {
          return {
            ...controlBaseStyles<Option, IsMulti, Group>(baseStyles, state),
            boxShadow: 'none',
            background: 'none',
            ...styles?.control?.(baseStyles, state),
          };
        }

        return {};
      },
    }),
    [theme, styles]
  );
};

export const SlotContainer = styled.div`
  line-height: 10px;
`;

export const OptionLabel = styled(EllipsisText)`
  flex: 1;
`;

export const Select = styled(ReactSelect)`
  .select__control::before {
    mask-composite: exclude;
  }

  .select__menu-list::before {
    mask-composite: exclude;
  }
` as typeof ReactSelect;
