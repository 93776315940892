export const FONTS = {
  body: {
    fontSize: 16,
    lineHeight: 24,
  },
  hero: {
    fontSize: 32,
    lineHeight: 40,
  },
  caption: {
    fontSize: 12,
    lineHeight: 16,
  },
  subTitle: {
    fontSize: 24,
    lineHeight: 32,
  },
};
