import { LineChart } from 'recharts';
import styled, { css } from 'styled-components';

import { sideGradientBorder } from 'polli-commons-fe/styles/mixins';

import { Flex } from '../styles';
import { EmptyPlaceholder as EmptyPlaceholderComponent } from '../empty-placeholder';

import { IncomeChartProps } from './types';

export const ChartContainer = styled.div<Pick<IncomeChartProps, 'chainType'>>`
  height: 556px;
  position: relative;
  border-radius: 36px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  padding: 24px;
  display: flex;
  flex-direction: column;

  ${({ theme, chainType }) => css`
    background: ${chainType === 'LAVA'
      ? theme.config.popup.background.lava
      : theme.config.popup.background.main};

    ${theme.responsive.isMobile &&
    css`
      .recharts-label tspan {
        font-size: 10px;
      }
    `}
  `}

  &::after {
    top: 50%;
    content: '';
    z-index: -1;
    width: 372px;
    height: 351px;
    right: 0;
    position: absolute;
    filter: blur(92px);
    border-radius: 372px;
    transform: translateY(-50%);
    background: linear-gradient(
      214deg,
      rgba(36, 74, 221, 0.1) 8.76%,
      rgba(84, 110, 210, 0.1) 82.49%
    );
  }

  .recharts-active-dot {
    display: none;
  }

  .recharts-cartesian-axis-tick-value tspan {
    font-size: 12px;
  }
`;

export const EmptyPlaceholder = styled(EmptyPlaceholderComponent)`
  top: 43%;
  height: 85%;
  background-size: contain;
`;

const chartContainerWithBackground = css`
  padding: 10px 0;
  background: linear-gradient(
      270deg,
      rgba(251, 251, 251, 0) 0%,
      rgba(251, 251, 251, 0.06) 8.58%,
      rgba(251, 251, 251, 0.06) 79.8%,
      rgba(251, 251, 251, 0) 100%
    ),
    linear-gradient(
      270deg,
      rgba(254, 202, 68, 0) 2.69%,
      rgba(254, 202, 68, 0.1) 12.86%,
      rgba(141, 201, 42, 0.1) 33.68%,
      rgba(42, 201, 125, 0.1) 44.86%,
      rgba(9, 174, 197, 0.1) 58.57%,
      rgba(9, 120, 197, 0.1) 77.62%,
      rgba(9, 120, 197, 0) 96.93%
    );

  ${sideGradientBorder({
    sides: {
      top: 1,
      bottom: 1,
    },
    color:
      'linear-gradient(225.75deg, rgba(236, 205, 43, 0.4) 20.01%, rgba(3, 151, 80, 0.4) 54.9%, rgba(24, 124, 198, 0.4) 86.45%)',
  })}
`;

export const ChartInner = styled(Flex)`
  height: 100%;
  flex-direction: column;
  gap: 16px;
`;

export const DateLineChart = styled(LineChart)`
  z-index: -1;
`;

export const WithdrawalLegendCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;

  ${({ theme }) => css`
    border: ${theme.config.transparentBorder};
    background: ${theme.colors.yellowGradient};
  `}
`;

export const RewardsChartContainer = styled.div<{
  dataHasCrossChainRewards: boolean;
}>`
  height: ${({ dataHasCrossChainRewards }) =>
    dataHasCrossChainRewards ? '45%' : '70%'};
`;

export const CrossChainRewardsChartContainer = styled.div`
  min-height: 25%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CrossChainRewardsChartInner = styled.div`
  ${chartContainerWithBackground};
  height: 100%;
`;

export const APRChartContainer = styled.div`
  min-height: 20%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const APRChartInner = styled.div`
  ${chartContainerWithBackground};
  height: 100%;
`;
