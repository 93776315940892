import * as Yup from 'yup';

import { FormValues } from './types';

export const validationSchema = Yup.object().shape({
  code: Yup.string().required(),
});

export const initialValues: FormValues = {
  code: '',
};
