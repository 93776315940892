import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import {
  cosmosApiInstance,
  ethereumApiInstance,
  notificationsApiInstance,
} from 'polli-commons-fe/api/instance';

interface Error {
  data?: any;
  status?: number;
  message?: string;
}

export const axiosBaseQuery =
  ({
    instance,
  }: {
    instance: AxiosInstance;
  }): BaseQueryFn<
    {
      url: string;
      data?: AxiosRequestConfig['data'];
      method: AxiosRequestConfig['method'];
      headers?: AxiosRequestConfig['headers'];
      timeout?: AxiosRequestConfig['timeout'];
    },
    unknown,
    Error
  > =>
  async ({ url, data, method, timeout, headers }, { signal }) => {
    try {
      const result = await instance({
        url,
        data,
        method,
        signal,
        headers,
        timeout,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          message: err.message,
          data: err.response?.data,
          status: err.response?.status,
        },
      };
    }
  };

export const ethereumApiQuery = axiosBaseQuery({
  instance: ethereumApiInstance,
});

export const cosmosApiQuery = axiosBaseQuery({
  instance: cosmosApiInstance,
});

export const notificationsApiQuery = axiosBaseQuery({
  instance: notificationsApiInstance,
});

export interface ResponseFuncProps {
  url: string;
  data: AxiosRequestConfig['data'];
}

export interface MockHandler {
  url: string;
  method: string;
  timeout?: number;
  responseData?: any;
  responseFunc?: (value: ResponseFuncProps) => any;
}

export const mockBaseQuery =
  ({
    handlers,
  }: {
    handlers: MockHandler[];
  }): BaseQueryFn<
    {
      url: string;
      data?: AxiosRequestConfig['data'];
      method: AxiosRequestConfig['method'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    Error
  > =>
  ({ url, data, method }) =>
    new Promise((resolve, reject) => {
      const handler = handlers.find(
        (hand) => hand.url === url && hand.method === method
      );

      if (!handler) {
        reject({
          error: {
            status: 404,
            message: 'No handler',
          },
        });
      }

      const { responseData, responseFunc } = handler!;

      setTimeout(() => {
        resolve({
          data: responseFunc ? responseFunc({ url, data }) : responseData,
        });
      }, handler!.timeout ?? 1500);
    });
