export const accentGradient = Object.freeze({
  blue: '#187CC6',
  green: '#039750',
  yellow: '#ECCD2B',
});

export const accent = Object.freeze({
  primary: {
    main: `linear-gradient(218deg, ${accentGradient.yellow} 0%, ${accentGradient.green} 52.51%, ${accentGradient.blue} 100%)`,
    hover:
      'linear-gradient(0deg, rgba(251, 251, 251, 0.15) 0%, rgba(251, 251, 251, 0.15) 100%), linear-gradient(218deg, #FDDD38 0%, #07AC5D 52.51%, #2386CE 100%)',
    active:
      'linear-gradient(0deg, rgba(251, 251, 251, 0.25) 0%, rgba(251, 251, 251, 0.25) 100%), linear-gradient(218deg, #FDDD38 0%, #07AC5D 52.51%, #2386CE 100%)',
  },
  secondary: {
    main: 'linear-gradient(218deg, rgba(236, 205, 43, 0.16) 0%, rgba(3, 151, 80, 0.16) 52.51%, rgba(24, 124, 198, 0.16) 100%), rgba(251, 251, 251, 0.10)',
    hover:
      'linear-gradient(0deg, rgba(4, 19, 1, 0.30) 0%, rgba(4, 19, 1, 0.30) 100%), linear-gradient(218deg, rgba(253, 221, 56, 0.16) 0%, rgba(7, 172, 93, 0.16) 52.51%, rgba(35, 134, 206, 0.16) 100%)',
    active:
      'linear-gradient(0deg, rgba(4, 19, 1, 0.40) 0%, rgba(4, 19, 1, 0.40) 100%), linear-gradient(218deg, rgba(253, 221, 56, 0.16) 0%, rgba(7, 172, 93, 0.16) 52.51%, rgba(35, 134, 206, 0.16) 100%)',
  },
});

const lavaGradient = Object.freeze({
  secondary: 'linear-gradient(207deg, #D7001F -1.17%, #FFD600 105.2%)',
  main: 'linear-gradient(218deg, rgba(215, 0, 31, 0.24) 0%,rgba(255, 214, 0, 0.24) 100%)',
  tertiary:
    'linear-gradient(268deg, rgba(215, 0, 31, 0.10) -2%, rgba(255, 214, 0, 0.10) 100.58%)',
});

export const black = '#101711' as string;

export const white = Object.freeze({
  primary: {
    main: '#FBFBFB',
    hover: '#BDC1BC',
    active: '#A4AAA3',
  },
  secondary: {
    main: 'rgba(251, 251, 251, 0.1)',
    hover: 'rgba(251, 251, 251, 0.25)',
    active: 'rgba(251, 251, 251, 0.35)',
  },
});

export const grey = Object.freeze({
  primary: {
    main: '#7F867D',
    hover: '#60695E',
    active: '#3F4B3D',
  },
});

export const lightGrey = Object.freeze({
  primary: {
    main: '#DFE1DE',
    hover: '#CDCFCC',
    active: '#BBBBBB',
  },
});

export const red = Object.freeze({
  additional: {
    main: '#F16464',
  },
  primary: {
    main: '#E53333',
    hover: '#AD2B26',
    active: '#962821',
  },
});

export const orange = Object.freeze({
  primary: '#FFA80A',
});

const background =
  'linear-gradient(218deg, rgba(236, 205, 43, 0.04) 0%, rgba(3, 151, 80, 0.04) 52.51%, rgba(24, 124, 198, 0.04) 100%)' as string;

const modalBackground = '#041301' as string;

const reward = 'rgba(232, 219, 107, 0.36)';

const success = '#04A168';

const lightGrayOpacity = 'rgba(251, 251, 251, 0.5)';
const backgroundDots = 'rgb(245, 245, 105)';

const darkAccentGradient =
  'linear-gradient(226deg, rgba(236, 205, 43, 0.3) 20.01%, rgba(3, 151, 80, 0.3) 54.9%, rgba(24, 124, 198, 0.3) 86.45%)';
const yellowGradient =
  'linear-gradient(156.04deg, #D58611 18.03%, #F3C44A 87.98%)';

export const blue = Object.freeze({
  primary: {
    main: '#0978C5',
  },
  secondary: {
    main: '#0978C533',
  },
});

const yellow = '#F5F569';

const defaultWhite = '#ffffff';

export const LIGHT_COLORS = Object.freeze({
  red,
  grey,
  blue,
  black,
  white,
  yellow,
  orange,
  accent,
  reward,
  success,
  lightGrey,
  background,
  defaultWhite,
  lavaGradient,
  backgroundDots,
  yellowGradient,
  accentGradient,
  modalBackground,
  lightGrayOpacity,
  darkAccentGradient,
});
