import { FieldMetaProps } from 'formik';

import { InputProps } from 'polli-commons-fe/components';

import { showError } from './helpers';

export const controlError = <T = any>(
  meta: FieldMetaProps<T>,
  name: string,
  label?: InputProps['label']
) => {
  let error = meta && meta.touched && meta.error;
  if (!error) return undefined;
  return error.replace(name, typeof label === 'string' ? label : 'Value');
};

export const getErrorMessage = (
  error: any,
  defaultErrorMessage: string = 'Error'
) => {
  if (typeof error === 'string') {
    return error;
  }

  if (error?.response?.data?.errorMessage) {
    return error.response.data.errorMessage;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === 'object') {
    if ('data' in error && error.data) {
      if (typeof error.data === 'object') {
        if (
          'errorMessage' in error.data &&
          typeof error.data.errorMessage === 'string'
        ) {
          return error.data.errorMessage;
        } else if (
          'error' in error.data &&
          typeof error.data.error === 'string'
        ) {
          return error.data.error;
        } else if ('message' in error && typeof error.message === 'string') {
          return error.message;
        } else {
          return defaultErrorMessage;
        }
      } else if ('message' in error && typeof error.message === 'string') {
        return error.message;
      } else {
        return defaultErrorMessage;
      }
    }

    if ('message' in error && typeof error.message === 'string') {
      return error.message;
    }
  }

  return defaultErrorMessage;
};

export const handleError = (
  error: any,
  defaultErrorMessage: string = 'Error'
) => {
  showError(getErrorMessage(error, defaultErrorMessage));
};
