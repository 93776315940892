import styled, { css } from 'styled-components';

import { Flex } from 'polli-commons-fe/components/styles';

export const Container = styled(Flex)`
  flex-wrap: wrap;
  gap: 16px;
`;

export const Divider = styled.span`
  width: 1px;
  ${({ theme }) => css`
    background-color: ${theme.config.presets.divider};

    ${theme.responsive.isDesktop &&
    css`
      margin: 0 40px;
    `}

    ${theme.responsive.isTablet &&
    css`
      margin: 0 16px;
    `}
  `}
`;
