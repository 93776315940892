import styled from 'styled-components';

export const RetryRequestContainer = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
`;
