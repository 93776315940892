import styled, { css } from 'styled-components';

import { caption } from 'polli-commons-fe/styles';
import { ChainType } from 'polli-commons-fe/types';
import { Flex } from 'polli-commons-fe/components/styles';

export const Tooltip = styled(Flex)<{ chainType?: ChainType }>`
  gap: 12px;
  padding: 16px;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;

  * {
    ${caption};
  }

  ${({ theme, chainType }) => css`
    border: ${theme.config.transparentBorder};
    background: ${chainType === 'LAVA'
      ? theme.config.lavaTableRow.background
      : theme.config.tableRow.background};
    width: ${theme.responsive.isMobile ? '260px' : '268px'};
    backdrop-filter: blur(12px);
  `}
`;

export const TooltipRowDivider = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 32px;
  background: ${({ theme }) => theme.colors.accent.secondary.main};
`;

export const rowTitleCircle = css`
  border-radius: 40px;
  width: 8px;
  height: 8px;
`;

export const RewardRowTitleIcon = styled.div`
  ${rowTitleCircle};
  background: #e4e939;
`;

export const CompoundTitleIcon = styled.div`
  ${rowTitleCircle};
  background: #07f43b;
`;

export const WithdrawalTitleIcon = styled.div`
  ${rowTitleCircle};
  background: ${({ theme }) => theme.colors.accentGradient.yellow};
`;
