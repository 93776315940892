import { isMobile } from '../../hooks';

import { PopupProps } from './types';
import { StyledPopup } from './styles';

const Popup = ({ width, height, children, maxWidth, ...props }: PopupProps) => {
  const mobile = isMobile();

  return (
    <StyledPopup
      {...props}
      arrow={false}
      $height={height}
      $maxWidth={maxWidth}
      $width={mobile ? '90vw' : width}
    >
      {/*@ts-ignore*/}
      {children}
    </StyledPopup>
  );
};

export { Popup };
export type { PopupProps };
