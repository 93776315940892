import axios from 'axios';

const ETHEREUM_BASE_URL = '/api-ethereum';
const COSMOS_BASE_URL = '/api-cosmos';
const NOTIFICATIONS_BASE_URL = '/api-notification';

export const ethereumApiInstance = axios.create({ baseURL: ETHEREUM_BASE_URL });

export const cosmosApiInstance = axios.create({ baseURL: COSMOS_BASE_URL });

export const notificationsApiInstance = axios.create({
  baseURL: NOTIFICATIONS_BASE_URL,
});

export const apiInstances = [
  ethereumApiInstance,
  cosmosApiInstance,
  notificationsApiInstance,
];
