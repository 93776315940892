import styled from 'styled-components';
import { motion } from 'framer-motion';

import bgStars from 'assets/deposit-confirmation/success-stars.svg';

export const Container = styled(motion.div)`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: url(${bgStars}) no-repeat center / contain;
  flex-direction: column;
  gap: 40px;
  display: flex;
  overflow: auto;
`;

export const InfoContainer = styled.div`
  width: 488px;
  flex-direction: column;
  overflow: auto;
  display: flex;
  gap: 24px;
`;

export const InfoBlurWrapper = styled.div`
  position: relative;
  padding: 24px;
`;

export const InfoBackground = styled.div`
  width: 100%;
  height: 100%;
  inset: 0;
  position: absolute;
  border-radius: 54px;
  background: rgba(4, 19, 1, 0.01);
  filter: blur(10.5px);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  z-index: -1;
`;
