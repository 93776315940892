import { HTMLAttributes } from 'react';

import { Popup } from '../../popup';
import { SortTableOrder } from '../../../types';
import { HeadersRow } from '../../table/headers-row';

import * as Styles from './styles';

export const SortableHeader = ({
  info,
  order,
  children,
  disabled,
  ...props
}: {
  info?: string;
  disabled?: boolean;
  order?: SortTableOrder;
} & HTMLAttributes<HTMLButtonElement>) => {
  return info ? (
    <Popup
      on="hover"
      width="400px"
      trigger={
        <div>
          <Styles.InfoSortableButton
            type="button"
            $order={order}
            disabled={disabled}
            {...props}
          >
            <HeadersRow.Header>{children}</HeadersRow.Header>
            <Styles.InfoIcon />
            {!disabled && <Styles.SortIcon />}
          </Styles.InfoSortableButton>
        </div>
      }
    >
      {info}
    </Popup>
  ) : (
    <Styles.SortableHeaderButton
      type="button"
      $order={order}
      disabled={disabled}
      {...props}
    >
      <HeadersRow.Header>{children}</HeadersRow.Header>
      {!disabled && <Styles.SortIcon />}
    </Styles.SortableHeaderButton>
  );
};
