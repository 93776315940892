import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

export const maxValidatorsExceededSlice = createSlice({
  initialState: false,
  name: 'maxValidatorsExceeded',
  reducers: {
    setMaxValidatorsExceeded: (_, { payload }: PayloadAction<boolean>) =>
      payload,
  },
});

export const {
  actions: { setMaxValidatorsExceeded },
} = maxValidatorsExceededSlice;

export const selectMaxValidatorsExceeded = (store: RootState) =>
  store.maxValidatorsExceeded;
