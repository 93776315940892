import { useMemo, Fragment, useState } from 'react';

import {
  Flex,
  EllipsisText,
  OverlappingIconList,
} from 'polli-commons-fe/components';

import { Icon } from 'components';

import * as Styles from './styles';
import { ListPopupProps, HandleIconProps } from './types';

const HandleIcon = ({ chain, iconMapper }: HandleIconProps) =>
  chain in iconMapper ? (
    iconMapper[chain as keyof typeof iconMapper]
  ) : (
    <Icon.CoinPlaceholder />
  );

export const ListPopup = ({ data, iconMapper }: ListPopupProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const sortedData = useMemo(
    () => [...data].sort((chain) => (chain in iconMapper ? -1 : 1)),
    [data, iconMapper]
  );

  return (
    <Styles.Popup
      on="hover"
      width="200px"
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      position={['right center', 'top center']}
      trigger={
        <Flex gap={4} alignItems="center">
          <OverlappingIconList
            data={sortedData.slice(0, 3)}
            renderIcon={(chain) => (
              <Styles.IconWrapper>
                <HandleIcon chain={chain} iconMapper={iconMapper} />
              </Styles.IconWrapper>
            )}
          />

          <Styles.ChevronIcon $isOpen={isOpen} />
        </Flex>
      }
    >
      <Flex column gap={16}>
        {sortedData.map((chain) => (
          <Fragment key={chain}>
            <Styles.PopupRow>
              <HandleIcon chain={chain} iconMapper={iconMapper} />

              <EllipsisText>{chain}</EllipsisText>
            </Styles.PopupRow>
          </Fragment>
        ))}
      </Flex>
    </Styles.Popup>
  );
};
