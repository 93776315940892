import * as Sentry from '@sentry/react';
import { Component, PropsWithChildren } from 'react';

import { Button } from 'polli-commons-fe/components/button';
import { BaseAuthPage } from 'polli-commons-fe/components/auth-page';
import { clearCacheAndReload } from 'polli-commons-fe/utils/helpers';
import { handleError, getErrorMessage } from 'polli-commons-fe/utils/error';

import { EllipsisText } from '../ellipsis-text';

import * as Styles from './styles';

export class ErrorBoundary extends Component<
  PropsWithChildren,
  { error: unknown }
> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: unknown) {
    return { error: getErrorMessage(error) };
  }

  render() {
    if (this.state.error) {
      return (
        <BaseAuthPage title="Something went wrong">
          <Styles.ErrorMessageWrapper>
            <EllipsisText>{getErrorMessage(this.state.error)}</EllipsisText>
            <Button
              text="Clear cache"
              onClick={async () => {
                try {
                  this.setState({ error: null });

                  clearCacheAndReload();
                } catch (error) {
                  handleError(error);
                }
              }}
            />
          </Styles.ErrorMessageWrapper>
        </BaseAuthPage>
      );
    }

    return this.props.children;
  }

  componentDidCatch(error: unknown) {
    Sentry.captureException(error);
  }
}
