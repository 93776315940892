import { CosmosChainType } from 'polli-commons-fe/types';

import lavaBgStars from 'assets/deposit-confirmation/lava-bg-stars.svg';
import cosmosBgStars from 'assets/deposit-confirmation/cosmos-bg-stars.svg';

export const iconBgImage: Record<CosmosChainType, string> = {
  LAVA: lavaBgStars,
  SECRET: cosmosBgStars,
  COSMOS: cosmosBgStars,
};
