import { TableRowProps } from '../types';
import { TranslucentCaption } from '../../styles';
import { EllipsisText } from '../../ellipsis-text';
import { generateTemplate } from '../../table/helpers';

import * as Styles from './styles';
import { TableRowContainer } from './styles';

export const Desktop = ({
  values,
  headers,
  gap = 16,
  template,
  padding = '16px',
  ...props
}: TableRowProps) => {
  const resultTemplate = template || generateTemplate(values.length);

  return (
    <TableRowContainer
      $gap={gap}
      $padding={padding}
      $template={resultTemplate}
      {...props}
    >
      {values.map((item, index) => {
        const header = headers && headers[index];

        return (
          <Styles.ItemContainer key={index}>
            {header &&
              (typeof header === 'string' ? (
                <EllipsisText>
                  <TranslucentCaption as="div">{header}</TranslucentCaption>
                </EllipsisText>
              ) : (
                header
              ))}

            {typeof item === 'string' ? (
              <EllipsisText>{item}</EllipsisText>
            ) : (
              item
            )}
          </Styles.ItemContainer>
        );
      })}
    </TableRowContainer>
  );
};

export { TableRowContainer };
export type { TableRowProps };
