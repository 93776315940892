export default {
  SIGN_OUT_ERROR: 'Sign out error',
  CONNECT_WALLET: 'Please connect wallet',
  CONNECTION_REJECTED: 'Connection rejected',
  QR_CODE_ERROR: 'Error when requesting qr code',
  CONFIRM_SIGN_UP_ERROR: 'Confirm sign up error',
  PASSWORD_CHANGED: 'Password changed successfully',
  CUSTOM_WALLET_CONNECTED: 'Custom wallet connected',
  CODE_VERIFICATION_ERROR: 'Code verification error',
  WALLET_CONNECTION_ERROR: 'Wallet connection error',
  CHAIN_NOT_SUPPORTED: 'Selected chain is not supported',
  TWO_FACTOR_ENABLED: '2FA Verification successfully enabled',
  TWO_FACTOR_DISABLED: '2FA Verification successfully disabled',
  EMAIL_CHANGED: 'The email address has been successfully changed',
  USER_EMAIL_NOT_EXISTING: 'User email not existing, please sign up again',
  RECEIVING_BALANCE_ERROR: 'An error occurred while receiving the balance',
  CHECK_EMAIL:
    'Please, check your email. We have sent you an email with a code.',
  RESENDING_CODE_ERROR:
    'An error occurred when resending the email verification code',
};
