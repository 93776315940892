import { CSSProperties, HTMLAttributes } from 'react';

import { isMobile } from '../../hooks';

import * as Styles from './styles';

export const TransparentBackground = ({
  children,
  backgroundStyles,
  ...props
}: { backgroundStyles?: CSSProperties } & HTMLAttributes<HTMLDivElement>) => {
  const mobile = isMobile();

  if (mobile) return children;

  return (
    <Styles.Container {...props}>
      <Styles.BlurBackground style={backgroundStyles} />
      {children}
    </Styles.Container>
  );
};
