import styled, { css } from 'styled-components';

export const Text = styled.span<{ selected: boolean }>`
  ${({ theme, selected, children }) => css`
    ${children === 'Max' &&
    css`
      font-weight: 600;

      ${!selected &&
      css`
        background: ${theme.colors.accent.primary.main};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `}
    `}
  `}
`;
