import { ReactNode, PropsWithChildren } from 'react';

import { Icon } from '../icons';
import { Flex } from '../styles';
import { BaseQueryProps } from '../../types';
import { RetryRequest } from '../retry-request';
import { EmptyPlaceholder } from '../empty-placeholder';

import * as Styles from './styles';

export interface DataFetchWrapperProps
  extends Partial<BaseQueryProps>,
    PropsWithChildren {
  hasData: boolean;
  fetchingIcon?: ReactNode;
  emptyTitle?: string | null;
  fetchingMessage?: ReactNode;
  placeholderElement?: ReactNode;
}

export const DataFetchWrapper = ({
  isError,
  refetch,
  hasData,
  children,
  isFetching,
  fetchingIcon,
  placeholderElement,
  emptyTitle = 'You have no data yet',
  fetchingMessage = 'Retrieving data from network',
}: DataFetchWrapperProps) => {
  let innerElement: ReactNode = emptyTitle;

  if (isFetching) {
    innerElement = (
      <Flex column gap={16} alignItems="center">
        <Flex gap={16} alignItems="center">
          {fetchingMessage}
          {fetchingIcon && (
            <Styles.FetchingIconContainer>
              {fetchingIcon}
            </Styles.FetchingIconContainer>
          )}
        </Flex>

        <Icon.Loader width={48} height={48} />
      </Flex>
    );
  }

  if (isError) {
    innerElement = (
      <RetryRequest onRetry={refetch} error="Error fetching data" />
    );
  }

  if (!hasData || isFetching || isError) {
    return (
      <Styles.PlaceholderWrapper>
        {placeholderElement}

        <EmptyPlaceholder>{innerElement}</EmptyPlaceholder>
      </Styles.PlaceholderWrapper>
    );
  }

  return children;
};
