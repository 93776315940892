import * as Styles from './styles';
import { PageProps } from './types';

export const Page = ({
  style,
  hasLogo,
  children,
  hasImage,
  className,
  showBackground = true,
  childrenContainerStyle,
}: PageProps) => {
  return (
    <Styles.Component style={style} className={className}>
      {showBackground && <Styles.Background hasImage={hasImage} />}

      <Styles.Content>
        {hasLogo ? (
          <Styles.ChildrenContainer style={childrenContainerStyle}>
            <Styles.Logo />

            <div>{children}</div>
          </Styles.ChildrenContainer>
        ) : (
          children
        )}
      </Styles.Content>
    </Styles.Component>
  );
};
