import { createApi } from '@reduxjs/toolkit/query/react';

import { cosmosApiQuery } from 'polli-commons-fe/store/query';

import { URL } from 'api/constants';
import { useCompoundEnabled } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectAuthUser } from 'store/slices/auth';

import { GrantData, StakingGrantee } from './types';

export const userGrantsTagTypes = ['user-grants'];

export const userGrantsApi = createApi({
  baseQuery: cosmosApiQuery,
  reducerPath: 'userGrantsApi',
  tagTypes: userGrantsTagTypes,
  endpoints: (build) => ({
    list: build.query<GrantData[], string>({
      providesTags: userGrantsTagTypes,
      query: (userUUID) => ({
        method: 'get',
        url: URL.USER_GRANTS.replace('{userUUID}', userUUID),
      }),
    }),
  }),
});

export const { useListQuery } = userGrantsApi;

export const useUserGrantsQuery = (
  walletAddress: string | null | undefined
) => {
  const authUser = useAppSelector(selectAuthUser);
  const compoundEnabled = useCompoundEnabled(walletAddress);

  return useListQuery(authUser?.sub!, {
    skip: !authUser?.sub || !compoundEnabled || !walletAddress,
  });
};

export type { GrantData, StakingGrantee };
