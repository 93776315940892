import PlaceholderBackgroundDark1x from '../../assets/images/placeholder-background-dark.png';
import PlaceholderBackgroundLight1x from '../../assets/images/placeholder-background-light.png';
import PlaceholderBackgroundDark2x from '../../assets/images/placeholder-background-dark@2x.png';
import PlaceholderBackgroundDark3x from '../../assets/images/placeholder-background-dark@3x.png';
import PlaceholderBackgroundLight2x from '../../assets/images/placeholder-background-light@2x.png';
import PlaceholderBackgroundLight3x from '../../assets/images/placeholder-background-light@3x.png';

import { ImageData } from './types';

export const PlaceholderBackgroundLight: ImageData = {
  alt: 'placeholder',
  src: PlaceholderBackgroundLight1x,
  srcSet: `${PlaceholderBackgroundLight1x} 1x, ${PlaceholderBackgroundLight2x} 2x, ${PlaceholderBackgroundLight3x} 3x`,
};

export const PlaceholderBackgroundDark: ImageData = {
  alt: 'placeholder',
  src: PlaceholderBackgroundDark1x,
  srcSet: `${PlaceholderBackgroundDark1x} 1x, ${PlaceholderBackgroundDark2x} 2x, ${PlaceholderBackgroundDark3x} 3x`,
};
