import { createApi } from '@reduxjs/toolkit/query/react';

import { Currency } from 'polli-commons-fe/types';
import { URL } from 'polli-commons-fe/api/constants';
import { addParamsToUrl } from 'polli-commons-fe/utils/query';
import { ethereumApiQuery } from 'polli-commons-fe/store/query';

import { ExchangeRateResponse, ExchangeRatesResponse } from './types';

export const exchangeRatesApi = createApi({
  baseQuery: ethereumApiQuery,
  reducerPath: 'exchangeRatesApi',
  endpoints: (build) => ({
    getExchangeRates: build.query<ExchangeRatesResponse, Currency>({
      query: (quoteCurrency) => ({
        method: 'get',
        url: addParamsToUrl(URL.EXCHANGE_RATES, {
          quoteCurrency,
        }),
      }),
    }),
  }),
});

export const { useGetExchangeRatesQuery } = exchangeRatesApi;

export type { ExchangeRateResponse, ExchangeRatesResponse };
