import { createApi } from '@reduxjs/toolkit/query/react';

import { cosmosApiQuery } from 'polli-commons-fe/store/query';
import { getSortParams, addParamsToUrl } from 'polli-commons-fe/utils/query';

import { URL } from 'api/constants';

import {
  LavaRestakeDelegationsRequest,
  LavaRestakeDelegationsResponse,
} from './types';

export const lavaRestakeTagTypes = ['lava-restake'];

export const lavaRestakeApi = createApi({
  baseQuery: cosmosApiQuery,
  reducerPath: 'lavaRestakeApi',
  tagTypes: lavaRestakeTagTypes,
  endpoints: (build) => ({
    getLavaRestakeDelegations: build.query<
      LavaRestakeDelegationsResponse,
      LavaRestakeDelegationsRequest
    >({
      providesTags: lavaRestakeTagTypes,
      query: ({ sort, delegatorAddress }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.LAVA_RESTAKE_DELEGATIONS.replace(
            '{delegatorAddress}',
            delegatorAddress
          ),
          getSortParams(sort)
        ),
      }),
    }),
  }),
});

export const { useGetLavaRestakeDelegationsQuery } = lavaRestakeApi;

export type { LavaRestakeDelegationsResponse };
