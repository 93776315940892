import styled from 'styled-components';

import { gradientBorder } from '../../styles/mixins';
import BackgroundStars from '../../assets/background-stars.svg';

export const Container = styled.div`
  ${gradientBorder};
  padding: 40px;
  border-radius: 36px;
`;

export const BlurBackground = styled.div`
  inset: 0;
  z-index: -10;
  position: absolute;
  border-radius: inherit;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  background:
    ${({ theme }) => theme.config.transparentBackground},
    url(${BackgroundStars}) no-repeat;
  background-size: contain;
`;
