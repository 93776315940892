/* eslint-disable perfectionist/sort-objects */
const zLayerOrder = {
  sidebar: 10,
  chartTooltipWrapper: 10,
  loader: 20,
  drawerOverlay: 25,
  drawerContent: 30,
  popupOverlay: 2000,
  popupContent: 2001,
  transactionLoader: 3000,
};

export default zLayerOrder;
