/* eslint-disable perfectionist/sort-objects */
import { ReactNode } from 'react';

import {
  Icon as CoreIcon,
  AccentGradientIcon,
  ThemeDependentIcon,
} from 'polli-commons-fe/components';

import { Icon } from 'components';

export const autoDelegationPreferences: Record<
  string,
  { label: string; icon: ReactNode; selectedIcon: ReactNode }
> = {
  validatorAnnualPercentageRate: {
    label: 'Maximum APR',
    selectedIcon: (
      <AccentGradientIcon>
        <CoreIcon.Interest />
      </AccentGradientIcon>
    ),
    icon: (
      <ThemeDependentIcon>
        <CoreIcon.Interest />
      </ThemeDependentIcon>
    ),
  },
  validatorCommissionRate: {
    label: 'Minimal commission',
    selectedIcon: (
      <AccentGradientIcon $iconColorParam="stroke">
        <Icon.MinimalCommission />
      </AccentGradientIcon>
    ),
    icon: (
      <ThemeDependentIcon $iconColorParam="stroke">
        <Icon.MinimalCommission />
      </ThemeDependentIcon>
    ),
  },
  totalStakedTokens: {
    label: 'Trusted validator',
    selectedIcon: (
      <AccentGradientIcon $iconColorParam="stroke">
        <Icon.TrustCommission />
      </AccentGradientIcon>
    ),
    icon: (
      <ThemeDependentIcon $iconColorParam="stroke">
        <Icon.TrustCommission />
      </ThemeDependentIcon>
    ),
  },
  votingPower: {
    label: 'Decentralized governance contribution',
    selectedIcon: (
      <AccentGradientIcon>
        <CoreIcon.Settings />
      </AccentGradientIcon>
    ),
    icon: (
      <ThemeDependentIcon>
        <CoreIcon.Settings />
      </ThemeDependentIcon>
    ),
  },
};
