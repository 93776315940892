import axios from 'axios';
import { decodeJWT } from '@aws-amplify/auth';

import { getWindowProvider } from 'polli-commons-fe/utils/cosmos';
import {
  ChainId,
  CosmosChainType,
  CosmosWalletProvider,
} from 'polli-commons-fe/types';

import { URL } from 'api/constants';

interface SignatureMessageResponse {
  messageToSign: string;
  walletAddress: string;
}

interface SignatureVerifyResponse {
  accessToken: string;
  refreshToken: string;
  walletAddress: string;
  chainType: CosmosChainType;
}

export const walletSignInAccessTokenKey = 'walletSignInAccessToken';
const walletSignInRefreshTokenKey = 'walletSignInRefreshToken';

export const saveWalletSignInTokens = (tokens: {
  accessToken: string;
  refreshToken?: string;
}) => {
  localStorage.setItem(walletSignInAccessTokenKey, tokens.accessToken);

  if (tokens.refreshToken) {
    localStorage.setItem(walletSignInRefreshTokenKey, tokens.refreshToken);
  }
};

export const clearWalletSignInTokens = () => {
  localStorage.removeItem(walletSignInAccessTokenKey);
  localStorage.removeItem(walletSignInRefreshTokenKey);
};

export const getWalletSignInAccessToken = () =>
  localStorage.getItem(walletSignInAccessTokenKey);

export const getWalletSignInRefreshToken = () =>
  localStorage.getItem(walletSignInRefreshTokenKey);

export const generateAndSaveWalletSignInTokens = async (params: {
  walletAddress: string;
  publicKey: string | null;
  chainType: CosmosChainType;
  providerName: CosmosWalletProvider;
}) => {
  const { chainType, publicKey, providerName, walletAddress } = params;
  const chainId = ChainId[chainType];
  const windowProvider = getWindowProvider(providerName);

  const generatedMessageResponse = await axios.post<SignatureMessageResponse>(
    URL.GENERATE_SIGNATURE_MESSAGE,
    {
      walletAddress,
    }
  );

  const { messageToSign } = generatedMessageResponse.data;

  if (walletAddress) {
    const signResponse = await windowProvider?.signArbitrary(
      chainId,
      walletAddress,
      messageToSign
    );

    if (signResponse?.signature) {
      const verifyResult = await axios.post<SignatureVerifyResponse>(
        URL.VERIFY_CHALLENGE,
        {
          publicKey,
          walletAddress,
          signature: signResponse.signature,
        }
      );

      const { accessToken, refreshToken } = verifyResult.data;
      saveWalletSignInTokens({ accessToken, refreshToken });

      const decoded = decodeJWT(accessToken);

      return decoded.payload.sub;
    }
  }
};
