import styled, { css } from 'styled-components';

import { BadgeColorType } from './types';

export const Container = styled.div<{ $colorType: BadgeColorType }>`
  gap: 4px;
  padding: 4px 12px;
  border-radius: 34px;
  align-items: center;
  display: inline-flex;

  svg {
    width: 16px;
    height: 16px;
  }

  ${({ theme, $colorType }) => css`
    color: ${theme.config.badge[$colorType].text};
    background-color: ${theme.config.badge[$colorType].background};

    svg path {
      fill: ${theme.config.badge[$colorType].text};
    }
  `}
`;
