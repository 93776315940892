import styled from 'styled-components';

import { caption } from 'polli-commons-fe/styles';

import { Flex } from '../styles';
import { EllipsisText } from '../ellipsis-text';

export const Currency = styled(Flex)`
  gap: 8px;
  font-weight: 600;
`;

export const Price = styled(EllipsisText)`
  > p {
    ${caption};
  }
`;
