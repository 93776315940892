import styled, { css } from 'styled-components';

export const Component = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  ${({ theme }) => css`
    background-color: ${theme.config.background};
  `}
`;

export const BackgroundImage = styled.img`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
`;

export const Background = styled.div`
  left: 50%;
  position: fixed;
  transform: translate(-50%, 0);
`;
