import { css } from 'styled-components';

export const hero = css`
  ${({ theme }) => css`
    font-size: ${theme.fonts.hero.fontSize}px;
    line-height: ${theme.fonts.hero.lineHeight}px;
  `};
`;

export const subTitle = css`
  ${({ theme }) => css`
    font-size: ${theme.fonts.subTitle.fontSize}px;
    line-height: ${theme.fonts.subTitle.lineHeight}px;
    font-weight: 500;
  `};
`;

export const body = css`
  ${({ theme }) => css`
    font-size: ${theme.fonts.body.fontSize}px;
    line-height: ${theme.fonts.body.lineHeight}px;
  `};
`;

export const caption = css`
  ${({ theme }) => css`
    font-size: ${theme.fonts.caption.fontSize}px;
    line-height: ${theme.fonts.caption.lineHeight}px;
  `};
`;
