import { MotionProps } from 'framer-motion';

export const NoDataPlaceholder = 'No data';

export const testIdAttribute = 'data-test-id';

export const motionOpacityProps: MotionProps = {
  exit: { opacity: 0 },
  animate: { opacity: 1 },
  initial: { opacity: 0 },
};
