import styled, { css } from 'styled-components';
import ReactPhoneNumberInput from 'react-phone-number-input/input';

import { Icon } from '../icons';
import { Flex, Caption } from '../styles';
import { gradientBorder } from '../../styles/mixins';

export const InputContainer = styled.div<{
  disabled: boolean;
  $hasError: boolean;
  $inputFocused?: boolean;
}>`
  gap: 8px;
  height: 56px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 28px;
  align-items: center;

  ${({ theme, disabled, $hasError, $inputFocused }) => css`
    background: ${theme.config.input.background.main};
    backdrop-filter: ${theme.config.input.backdropFilter};
    -webkit-backdrop-filter: ${theme.config.input.backdropFilter};
    border: ${theme.config.input.border};

    ${$inputFocused
      ? css`
          background: ${theme.config.input.background.active};

          ${!$hasError &&
          css`
            ${gradientBorder};
          `}
        `
      : css`
          box-shadow: ${theme.config.input.boxShadow.main};
        `}

    ${$hasError &&
    css`
      outline: 2px solid ${theme.colors.red.additional.main};
    `}

    ${disabled
      ? css`
          opacity: 0.6;
          pointer-events: none;
        `
      : css`
          ${!$inputFocused &&
          css`
            &:hover {
              background: ${theme.config.input.background.hover};
              box-shadow: ${theme.config.input.boxShadow.hover};
            }
          `}
        `};
  `}
`;

export const inputCss = css<{ hasLeftIcon: boolean }>`
  flex: 1;
  width: 100%;
  height: inherit;
  padding-right: 16px;
  background-color: inherit;

  ${({ theme, hasLeftIcon }) => css`
    caret-color: ${theme.config.text.primary.main};
    padding-left: ${hasLeftIcon ? '48px' : '16px'};

    &:-webkit-autofill {
      caret-color: ${theme.colors.black};
    }

    &::placeholder {
      color: ${theme.config.input.placeholder.primary};
    }

    &:focus {
      outline: unset;
    }
  `}
`;

export const Input = styled.input<{ hasLeftIcon: boolean }>`
  ${inputCss};
`;

export const PhoneInput = styled(ReactPhoneNumberInput)<{
  hasLeftIcon: boolean;
}>`
  ${inputCss};
` as typeof ReactPhoneNumberInput;

export const Label = styled.label<{ $hasValue: boolean }>`
  ${({ theme, $hasValue }) =>
    !$hasValue &&
    css`
      color: ${theme.colors.grey.primary.main};
    `}
`;

export const Error = styled(Caption)`
  color: ${({ theme }) => theme.colors.red.additional.main};
`;

export const LeftIconWrapper = styled(Flex)`
  left: 16px;
  z-index: -1;
  position: absolute;
  align-items: center;
`;

export const RightSlotWrapper = styled(Flex)`
  padding-right: 16px;
`;

export const greyIcon = css`
  path {
    fill: ${({ theme }) => theme.colors.grey.primary.main};
  }
`;

export const EyeGrey = styled(Icon.Eye)`
  ${greyIcon};
`;

export const EyeOffGrey = styled(Icon.EyeOff)`
  ${greyIcon};
`;
