import styled from 'styled-components';

import { Flex } from 'polli-commons-fe/components/styles';

export const Container = styled(Flex)`
  border-radius: 40px;
  justify-content: center;
  background: linear-gradient(156deg, #d48611 18.03%, #f3c44a 87.98%),
    linear-gradient(156deg, #1690ab 18.03%, #2db8d7 87.98%);
  box-shadow: 0 0 4px 0 rgba(224, 159, 39, 0.58);
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.colors.white.primary.main};
    }
  }
`;
