import { withRef } from 'polli-commons-fe/hoc';
import { Caption } from 'polli-commons-fe/components/styles';

import * as Styles from './styles';
import { BadgeProps, BadgeColorType } from './types';

export const Badge = ({
  icon,
  children,
  innerRef,
  rightIcon,
  colorType = 'success',
  ...props
}: BadgeProps) => {
  return (
    <Styles.Container ref={innerRef} $colorType={colorType} {...props}>
      {colorType == 'translucent' ? (
        <Caption>{children}</Caption>
      ) : (
        <>
          {icon}
          <Caption>{children}</Caption>
          {rightIcon}
        </>
      )}
    </Styles.Container>
  );
};

export const BadgeWithRef = withRef(Badge);

export type { BadgeColorType };
