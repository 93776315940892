import { motion } from 'framer-motion';
import styled from 'styled-components';

import bgImage from 'assets/deposit-confirmation/success-bg.png';

export const Container = styled(motion.div)`
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  background: url(${bgImage}) center / cover;
  flex-direction: column;
  gap: 40px;
  display: flex;
  z-index: ${({ theme }) => theme.zLayerOrder.transactionLoader};
`;
