import { HTMLAttributes } from 'react';

import { TableProps } from '../types';
import { SortTableOrder } from '../../../types';

import * as Styles from './styles';

interface HeadersRowProps
  extends Required<Pick<TableProps, 'headers' | 'template'>>,
    HTMLAttributes<HTMLDivElement>,
    Pick<TableProps, 'viewType'> {
  order?: SortTableOrder;
}

export const HeadersRow = ({
  order,
  headers,
  template,
  viewType = 'default',
  ...props
}: HeadersRowProps) => {
  return (
    <Styles.HeadersContainer
      $template={template}
      $viewType={viewType}
      {...props}
    >
      {headers.map((header, index) =>
        typeof header === 'string' ? (
          <Styles.Header key={index}>{header}</Styles.Header>
        ) : (
          <div key={index}>{header}</div>
        )
      )}
    </Styles.HeadersContainer>
  );
};

HeadersRow.Header = Styles.Header;
