import styled, { css } from 'styled-components';

import { caption } from 'polli-commons-fe/styles';
import { gradientBorder } from 'polli-commons-fe/styles/mixins';
import { SelectorsProps } from 'polli-commons-fe/components/selectors/types';

export const Text = styled.span<{ selected: boolean }>`
  font-size: inherit;
  line-height: inherit;
`;

export const Button = styled.button<
  {
    $selected?: string;
    $currentValue: string;
  } & Pick<SelectorsProps, 'styleType'>
>`
  ${caption};
  padding: 4px 12px;
  position: relative;
  border-radius: 24px;

  ${({ theme, $selected, styleType, $currentValue }) => {
    const isCurrentItemSelected = $currentValue === $selected;

    return css`
      ${isCurrentItemSelected
        ? css`
            box-shadow: 0 2px 4px 0 rgba(4, 19, 1, 0.04);
            color: ${theme.config.presets.selected.color};
            background: ${styleType === 'default'
              ? theme.config.presets.selected.background
              : theme.colors.lavaGradient.secondary};
          `
        : css`
            &:hover {
              box-shadow: 0 2px 4px 0 rgba(4, 19, 1, 0.04);
              background: ${theme.config.presets.hover.background};
            }

            &:active {
              box-shadow: 0 2px 4px 0 rgba(4, 19, 1, 0.04);
              background: ${theme.config.presets.active.background};

              ${!theme.isDarkTheme &&
              css`
                ${gradientBorder};

                &::before {
                  z-index: unset;
                }
              `}
            }
          `}
    `;
  }}
`;

export const Divider = styled.span`
  top: 50%;
  right: 0;
  width: 1px;
  height: 24px;
  position: absolute;
  border-radius: 8px;
  transform: translateY(-50%);
  background-color: ${({ theme }) => theme.config.presets.divider};
`;
