import styled, { css } from 'styled-components';

import { Button } from '../button';

export const FilterButton = styled(Button)<{ $filtersApplied: boolean }>`
  position: relative;

  ${({ theme, $filtersApplied }) =>
    $filtersApplied &&
    css`
      &::before {
        content: '';
        border-radius: 8px;
        position: absolute;
        width: 8px;
        height: 8px;
        background: ${theme.colors.red.primary.main};
        left: 14px;
        top: 1px;
      }
    `}
`;
