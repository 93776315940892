import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

export type LavaContributionsType = 'providers' | 'validators';

export const lavaContributionTypeSlice = createSlice({
  name: 'lavaContributionType',
  initialState: 'validators' as LavaContributionsType,
  reducers: {
    setLavaContributionType: (
      _,
      { payload }: PayloadAction<LavaContributionsType>
    ) => payload,
  },
});

export const {
  actions: { setLavaContributionType },
} = lavaContributionTypeSlice;

export const selectLavaContributionType = (store: RootState) =>
  store.lavaContributionType;
