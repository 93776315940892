import { useMediaQuery } from 'react-responsive';

const mobileMaxWidth = 767;
const tabletMaxWidth = 1199;

export const isDesktop = () => useMediaQuery({ minWidth: tabletMaxWidth + 1 });

export const isTablet = () =>
  useMediaQuery({ maxWidth: tabletMaxWidth, minWidth: mobileMaxWidth + 1 });

export const isTabletUp = () => useMediaQuery({ minWidth: mobileMaxWidth + 1 });

export const isMobile = () => useMediaQuery({ maxWidth: mobileMaxWidth });

export { useMediaQuery };
