import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';

import { Flex } from 'polli-commons-fe/components/styles';

export const QRCode = styled(QRCodeSVG)`
  width: 288px;
  height: 288px;
`;

export const Buttons = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
