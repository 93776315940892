import { createSelector } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AmountResponse } from 'polli-commons-fe/types';
import { ChainTypeToCurrencyLabel } from 'polli-commons-fe/types/data';
import {
  showError,
  determineChainTypeFromAddress,
} from 'polli-commons-fe/utils/helpers';

import { RootState } from 'store';
import { CosmosTransactionStatus } from 'types';

import { autoDelegationPreferences } from './config';
import {
  calculateTotalTokensToDelegate,
  distributeAmountAmongValidators,
} from './helpers';
import {
  stepsByTypeOrder,
  toProviderStepsOrder,
  DelegationProcessType,
  DelegationProcessState,
} from './types';

const initialState: DelegationProcessState = {
  retries: 0,
  status: null,
  showLoader: false,
  type: 'toValidator',
  currencyLabel: null,
  step: 'preferences',
  chainType: undefined,
  optimizerEnabled: false,
  totalTokensToDelegate: 0,
  delegationInProgress: false,
  selectedWalletAddress: null,
  delegationData: {
    items: {},
  },
  selectedPreferences: Object.keys(autoDelegationPreferences),
};

export const delegationProcessStateSlice = createSlice({
  initialState,
  name: 'delegationProcessState',
  reducers: {
    resetDelegationProcessState: () => initialState,
    closeLoader: (state) => {
      state.showLoader = false;
    },
    enableOptimizer: (state) => {
      state.optimizerEnabled = true;
    },
    toggleOptimizer: (state) => {
      state.optimizerEnabled = !state.optimizerEnabled;
    },
    setDelegationRetries: (state, action: PayloadAction<number>) => {
      state.retries = action.payload;
    },
    enableToProviderDelegation: (state) => {
      state.type = 'toProvider';
      state.step = toProviderStepsOrder[0];
    },
    setTotalTokensToDelegate: (state, action: PayloadAction<number>) => {
      state.totalTokensToDelegate = action.payload;
    },
    resetTransactionState: (state) => {
      state.status = null;
      state.retries = 0;
      state.delegationInProgress = false;
    },
    setDelegationStatus: (
      state,
      action: PayloadAction<CosmosTransactionStatus>
    ) => {
      state.status = action.payload;
    },
    setSelectedDelegationPreference: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.selectedPreferences = action.payload;
    },
    setDelegationFlowType: (
      state,
      action: PayloadAction<DelegationProcessType>
    ) => {
      state.type = action.payload;
      state.step = stepsByTypeOrder[action.payload][0];
    },
    handleDelegateError: (state, action: PayloadAction<string>) => {
      showError(action.payload);
      state.showLoader = false;
      state.retries = 0;
      state.status = null;
      state.delegationInProgress = false;
    },
    switchToProviderDelegation: (state) => ({
      ...initialState,
      type: 'toProvider',
      chainType: state.chainType,
      step: toProviderStepsOrder[0],
      currencyLabel: state.currencyLabel,
      selectedWalletAddress: state.selectedWalletAddress,
    }),
    switchToValidatorDelegation: (state) => ({
      ...initialState,
      type: 'toValidator',
      chainType: state.chainType,
      currencyLabel: state.currencyLabel,
      step: stepsByTypeOrder.toValidator[0],
      selectedWalletAddress: state.selectedWalletAddress,
    }),
    toggleDelegationPreference: (state, action: PayloadAction<string>) => {
      const index = state.selectedPreferences.indexOf(action.payload);
      if (index === -1) {
        state.selectedPreferences.push(action.payload);
      } else {
        state.selectedPreferences.splice(index, 1);
      }
    },
    setSelectedDelegationWallet: (state, action: PayloadAction<string>) => {
      const chainType = determineChainTypeFromAddress(action.payload);

      state.chainType = chainType;
      state.selectedWalletAddress = action.payload;
      state.currencyLabel = chainType && ChainTypeToCurrencyLabel[chainType];
    },
    startDelegation: (state) => {
      state.showLoader = true;
      state.delegationInProgress = true;

      state.delegationData.items = Object.fromEntries(
        Object.entries(state.delegationData.items).map(([address, data]) => {
          return [address, { ...data, isSuccess: false }];
        })
      );
    },
    addValidator: (state, action: PayloadAction<string>) => {
      state.delegationData.items = distributeAmountAmongValidators(
        state.totalTokensToDelegate,
        [...Object.keys(state.delegationData.items), action.payload]
      );

      state.totalTokensToDelegate = calculateTotalTokensToDelegate(
        state.delegationData.items
      );
    },
    removeDelegatedValidator: (state, action: PayloadAction<string>) => {
      delete state.delegationData.items[action.payload];

      state.delegationData.items = distributeAmountAmongValidators(
        state.totalTokensToDelegate,
        Object.keys(state.delegationData.items)
      );

      state.totalTokensToDelegate = calculateTotalTokensToDelegate(
        state.delegationData.items
      );
    },
    setDelegatedValidators: (state, action: PayloadAction<string[]>) => {
      state.delegationData.items = Object.fromEntries(
        Object.entries(state.delegationData.items).filter(
          ([validatorAddress]) => action.payload.includes(validatorAddress)
        )
      );

      state.delegationData.items = distributeAmountAmongValidators(
        state.totalTokensToDelegate,
        action.payload
      );
    },
    handleDelegateSuccess: (
      state,
      action: PayloadAction<{
        gasUsed: AmountResponse;
      }>
    ) => {
      state.delegationData.gasUsed = action.payload.gasUsed;
      state.status = 'success';

      state.delegationData.items = Object.fromEntries(
        Object.entries(state.delegationData.items).map(([address, data]) => {
          return [address, { ...data, isSuccess: true }];
        })
      );
    },
    previousDelegationStep: (state) => {
      if (
        state.type === 'onlyOptimizer' &&
        stepsByTypeOrder[state.type].indexOf(state.step) === 1
      ) {
        state.type = 'toValidator';
        state.step = stepsByTypeOrder[state.type][0];
        return;
      }

      const currentIndex = stepsByTypeOrder[state.type].indexOf(state.step);

      if (currentIndex === 0) {
        return initialState;
      }

      state.step = stepsByTypeOrder[state.type][currentIndex - 1];
    },
    setValidatorAmount: (
      state,
      action: PayloadAction<{ amount: string; address: string }>
    ) => {
      if (!state.delegationData.items[action.payload.address]) {
        state.delegationData.items[action.payload.address] = {
          isSuccess: false,
          amount: action.payload.amount,
        };
      } else if (!action.payload.amount) {
        delete state.delegationData.items[action.payload.address];
      } else {
        state.delegationData.items[action.payload.address].amount =
          action.payload.amount;
      }

      state.totalTokensToDelegate = calculateTotalTokensToDelegate(
        state.delegationData.items
      );
    },
    nextDelegationStep: (state) => {
      if (state.step === 'preferences' && state.type !== 'onlyOptimizer') {
        if (!state.totalTokensToDelegate) {
          showError('Please enter the amount of tokens to delegate');
          return;
        }

        if (state.selectedPreferences.length === 0) {
          showError('Please select at least one preference');
          return;
        }
      }

      if (state.step === 'summary') {
        if (!state.totalTokensToDelegate) {
          showError('Please enter the amount of tokens to delegate');
          return;
        }
      }

      const currentIndex = stepsByTypeOrder[state.type].indexOf(state.step);
      const nextStep = stepsByTypeOrder[state.type][currentIndex + 1];

      state.step = nextStep;
    },
  },
});

export const {
  actions: {
    closeLoader,
    addValidator,
    toggleOptimizer,
    startDelegation,
    enableOptimizer,
    nextDelegationStep,
    setValidatorAmount,
    setDelegationStatus,
    handleDelegateError,
    setDelegationRetries,
    setDelegationFlowType,
    handleDelegateSuccess,
    resetTransactionState,
    setDelegatedValidators,
    previousDelegationStep,
    setTotalTokensToDelegate,
    removeDelegatedValidator,
    switchToProviderDelegation,
    enableToProviderDelegation,
    toggleDelegationPreference,
    switchToValidatorDelegation,
    resetDelegationProcessState,
    setSelectedDelegationWallet,
    setSelectedDelegationPreference,
  },
} = delegationProcessStateSlice;

export const selectDelegationProcessState = (store: RootState) =>
  store.delegationProcessState;

export const selectSelectedDelegationPreferences = createSelector(
  [selectDelegationProcessState],
  (store) => store.selectedPreferences
);

export const selectDelegationProcessData = createSelector(
  [selectDelegationProcessState],
  (store) => store.delegationData
);

export const selectDelegatedValidators = createSelector(
  [selectDelegationProcessData],
  (store) => store.items
);

export const selectCurrentDelegationChainType = createSelector(
  [selectDelegationProcessState],
  (store) => store.chainType
);

export const selectDelegationAmount = (validatorAddress: string) =>
  createSelector(
    [selectDelegationProcessState],
    (store) => store.delegationData.items[validatorAddress]?.amount ?? ''
  );

export const selectCurrentDelegationStep = createSelector(
  [selectDelegationProcessState],
  (store) => store.step
);

export { autoDelegationPreferences };
