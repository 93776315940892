import styled from 'styled-components';

import { rowTitleCircle } from '../styles';

export const RewardsBreakdownItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 12px;
`;

export const RewardsBreakdownIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const RewardsBreakdownTitleIcon = styled.div`
  ${rowTitleCircle};
  background: #ffffff;
`;

export const OtherRewardIconWrapper = styled.div`
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 12px;
  height: 12px;
  padding: 2px;

  svg {
    width: 8px;
    height: 8px;

    path {
      fill: #000;
    }
  }
`;

export const OtherRewardIconsWrapper = styled.div`
  display: flex;

  :not(:first-child) {
    margin-left: -5px;
  }
`;
