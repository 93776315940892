import { Children, ReactElement, cloneElement, isValidElement } from 'react';

import { Popup } from '../popup';
import { Flex, Ellipsis } from '../styles';
import { useEllipsis, isDesktop as isDesktopHook } from '../../hooks';

import * as Styles from './styles';
import { EllipsisTextProps } from './types';

export const EllipsisText = ({
  modal,
  trigger,
  children,
  onPopUpClick,
  on = ['hover', 'focus'],
  ...props
}: EllipsisTextProps) => {
  const { use, setRef } = useEllipsis();

  const isDesktop = isDesktopHook();

  return (
    <Popup
      on={on}
      modal={modal}
      disabled={!use}
      closeOnDocumentClick
      maxWidth={isDesktop ? '50vw' : '90vw'}
      position={[
        'bottom left',
        'bottom center',
        'bottom right',
        'top left',
        'top center',
        'top right',
      ]}
      trigger={
        <Ellipsis {...props}>
          {Children.map(children, (child) => {
            if (!isValidElement(child)) {
              return <p ref={setRef}>{child}</p>;
            } else {
              return cloneElement(child as ReactElement, { ref: setRef });
            }
          })}
          {trigger ? <Flex flexShrink={0}>{trigger(use)}</Flex> : null}
        </Ellipsis>
      }
    >
      <Styles.Container onClick={onPopUpClick}>
        {Children.map(children, (child) => {
          if (!isValidElement(child)) {
            return child;
          } else {
            return child.props.children;
          }
        })}
      </Styles.Container>
    </Popup>
  );
};
