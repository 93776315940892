import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';

export const rewardsDrawerSlice = createSlice({
  name: 'rewardsDrawer',
  initialState: { opened: false },
  reducers: {
    openRewardsDrawer: (state) => {
      state.opened = true;
    },
    closeRewardsDrawer: (state) => {
      state.opened = false;
    },
  },
});

export const {
  reducer: rewardsDrawerReducer,
  actions: { openRewardsDrawer, closeRewardsDrawer },
} = rewardsDrawerSlice;

export const selectRewardsDrawer = (store: RootState) => store.rewardsDrawer;
