import detectEthereumProvider from '@metamask/detect-provider';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { handleNewEthAddress } from 'utils/helpers';

interface State {
  hasProvider: boolean;
  address: string | null;
  chainId: string | null;
  balance: string | null;
}

const initialState: State = {
  address: null,
  chainId: null,
  balance: null,
  hasProvider: false,
};

export const fetchMetamaskProviderStatus = createAsyncThunk(
  'metamask/fetchProviderStatus',
  async (_, { rejectWithValue }) => {
    try {
      const provider = await detectEthereumProvider({ silent: true });
      return Boolean(provider);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const connectMetamask = createAsyncThunk(
  'metamask/connect',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const accounts = await window.ethereum?.request({
        method: 'eth_requestAccounts',
      });

      const address = accounts[0];

      const { hasError } = await handleNewEthAddress(address, dispatch);

      if (hasError) return;

      const chainId = await window.ethereum?.request({
        method: 'eth_chainId',
      });

      const balance = await window.ethereum?.request({
        method: 'eth_getBalance',
        params: [accounts[0], 'latest'],
      });

      return { chainId, balance, address };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const metamaskSlice = createSlice({
  name: 'metamask',
  initialState: initialState,
  reducers: {
    disconnectMetamask: (state) => {
      state.address = null;
    },
    setMetamaskAddress: (state, { payload }: PayloadAction<string>) => {
      state.address = payload;
    },
    setMetamaskChainId: (state, { payload }: PayloadAction<string>) => {
      state.chainId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMetamaskProviderStatus.fulfilled, (state, action) => {
      if (action.payload) {
        state.hasProvider = action.payload;
      } else {
        return initialState;
      }
    });
    builder.addCase(connectMetamask.fulfilled, (state, action) => {
      if (action.payload) {
        state.address = action.payload.address;
        state.chainId = action.payload.chainId;
        state.balance = action.payload.balance;
      }
    });
  },
});

export const {
  reducer: metamaskReducer,
  actions: { disconnectMetamask, setMetamaskChainId },
} = metamaskSlice;

export const selectMetamask = (store: RootState) => store.metamask;
