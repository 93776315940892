import { ReactNode } from 'react';

import { ChainTypeIcon } from 'polli-commons-fe/types/data';
import { TableWithSortingProps } from 'polli-commons-fe/components/sortable-table';

export const supportedChainsIconMapper: Record<string, ReactNode> = {
  ...ChainTypeIcon,
  COSMOSHUB: ChainTypeIcon.COSMOS,
  COSMOSHUBT: ChainTypeIcon.COSMOS,
};

export const baseHeaders: TableWithSortingProps['headers'] = [
  {
    accessor: 'name',
    label: 'Provider',
  },
  'Chain',
  'Tokens',
  {
    label: 'Total Delegation',
    accessor: 'totalDelegation',
  },
  {
    label: 'Estimated APR',
    accessor: 'estimatedAnnualPercentageRate',
  },
  {
    label: 'Commission',
    accessor: 'commissionInPercentage',
  },
];
