import { FormikValues } from 'formik';
import { motion, AnimatePresence } from 'framer-motion';

import { isMobile } from 'polli-commons-fe/hooks';
import { Flex, Semibold } from 'polli-commons-fe/components/styles';
import {
  Popup,
  Badge,
  Button,
  Dialog,
  BadgeWithRef,
  Icon as CoreIcon,
} from 'polli-commons-fe/components';

import Messages from 'config/messages';
import { Icon } from 'components/icons';

import * as Styles from './styles';
import { EditFieldProps } from './types';

const statusBadgeMotionProps = {
  as: motion.div,
  exit: { y: -20, opacity: 0 },
  transition: { duration: 0.3 },
  animate: { y: 0, opacity: 1 },
  initial: { y: -20, opacity: 0 },
};

export const EditField = <Values extends FormikValues = FormikValues>({
  value,
  label,
  changed,
  onClick,
  dialogProps,
  confirmationRequired,
}: EditFieldProps<Values>) => {
  const mobile = isMobile();

  return (
    <Flex column gap={8}>
      <Styles.Label>{label}</Styles.Label>

      <Flex
        gap={16}
        alignItems="center"
        justifyContent={mobile ? 'space-between' : 'initial'}
      >
        <Flex wrap gap={16}>
          <Semibold>{value}</Semibold>

          <Dialog
            {...dialogProps}
            trigger={
              <Flex>
                <Button
                  text="Edit"
                  onClick={onClick}
                  icon={<Icon.Edit />}
                  styleType="gradient"
                />
              </Flex>
            }
          />
        </Flex>

        <AnimatePresence>
          {changed && (
            <Flex {...statusBadgeMotionProps}>
              <Badge icon={<CoreIcon.Checkmark />}>Changed</Badge>
            </Flex>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {confirmationRequired && (
            <Flex {...statusBadgeMotionProps}>
              <Popup
                on="hover"
                trigger={
                  <BadgeWithRef colorType="red">
                    Confirmation required
                  </BadgeWithRef>
                }
              >
                {Messages.CHECK_EMAIL}
              </Popup>
            </Flex>
          )}
        </AnimatePresence>
      </Flex>
    </Flex>
  );
};

export type { EditFieldProps };
