import styled, { css } from 'styled-components';

import { Icon } from '../../icons';
import { SortTableOrder } from '../../../types';
import { HeadersRow } from '../../table/headers-row';
import { gradientBorder, getAccentGradientText } from '../../../styles/mixins';

export const SortIcon = styled(Icon.TableSort)`
  ${({ theme }) => css`
    path {
      fill: ${theme.config.text.primary.main};
    }
  `}
`;

export const SortableHeaderButton = styled.button<{
  $order?: SortTableOrder;
}>`
  gap: 4px;
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 12px;

  &:disabled {
    opacity: 0.6;
  }

  ${({ theme, $order }) => css`
    ${$order &&
    css`
      ${gradientBorder};

      &::before {
        padding: 2px;
      }

      ${HeadersRow.Header} {
        ${getAccentGradientText()}
      }

      ${SortIcon} {
        ${$order === 'desc' &&
        css`
          path:nth-child(1) {
            fill: url(#default_gradient);
          }
        `}

        ${$order === 'asc' &&
        css`
          path:nth-child(2) {
            fill: url(#default_gradient);
          }
        `}
      }

      &:hover:not(:disabled) {
        &::before {
          background: ${theme.colors.accent.primary.hover};
        }
      }

      &:active:not(:disabled) {
        &::before {
          background: ${theme.colors.accent.primary.active};
        }

        ${HeadersRow.Header} {
          ${getAccentGradientText(theme.colors.accent.primary.active)}
        }

        ${SortIcon} {
          ${$order === 'desc' &&
          css`
            path:nth-child(1) {
              fill: url(#active_gradient);
            }
          `}

          ${$order === 'asc' &&
          css`
            path:nth-child(2) {
              fill: url(#active_gradient);
            }
          `}
        }
      }
    `}

    ${!$order &&
    css`
      &:hover:not(:disabled) {
        ${SortIcon} {
          path:nth-child(1) {
            fill: url(#default_gradient);
          }
        }

        ${HeadersRow.Header} {
          ${getAccentGradientText()}
        }
      }

      &:active:not(:disabled) {
        ${SortIcon} {
          path:nth-child(1) {
            fill: url(#active_gradient);
          }
        }

        ${HeadersRow.Header} {
          ${getAccentGradientText(theme.colors.accent.primary.active)}
        }
      }
    `}
  `}
`;

export const InfoIcon = styled(Icon.Info)`
  path {
    fill: ${({ theme }) => theme.colors.white.primary.main};
    fill-opacity: 0.5;
  }
`;

export const InfoSortableButton = styled(SortableHeaderButton)<{
  $order?: SortTableOrder;
}>`
  ${({ $order }) => css`
    &:hover {
      ${InfoIcon} {
        path {
          fill: url(#default_gradient);
        }
      }
    }

    &:active {
      ${InfoIcon} {
        path {
          fill: url(#active_gradient);
        }
      }
    }

    ${$order &&
    css`
      ${InfoIcon} {
        path {
          fill: url(#default_gradient);
        }
      }
    `}
  `}
`;
