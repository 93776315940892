import styled, { css } from 'styled-components';

import { CheckboxProps } from 'components/checkbox';

export const InputContainer = styled.div<
  Pick<CheckboxProps, 'checked' | 'disabled'>
>`
  width: 40px;
  height: 24px;
  padding: 0 4px;
  position: relative;
  border-radius: 12px;
  align-items: center;
  display: inline-flex;

  ${({ theme, checked, disabled }) => css`
    background-color: ${theme.config.toggle.background.main};

    ${disabled &&
    css`
      pointer-events: none;
    `}

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      border-radius: 50%;
      transition: transform 0.25s;
      background-color: ${theme.config.toggle.circle};

      ${checked &&
      css`
        transform: translateX(100%);
        background-color: ${theme.colors.white.primary.main};
      `}
    }

    &:hover {
      background-color: ${theme.config.toggle.background.hover};
    }

    ${checked &&
    css`
      background: ${theme.colors.accent.primary.main};

      &:hover {
        background: ${theme.colors.accent.primary.hover};
      }
    `}
  `}
`;
