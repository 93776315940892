import { createApi } from '@reduxjs/toolkit/query/react';

import { cosmosApiQuery } from 'polli-commons-fe/store/query';
import { getSortParams, addParamsToUrl } from 'polli-commons-fe/utils/query';

import { URL } from 'api/constants';
import { LavaRestakeProviderData } from 'store/api/lava-restake/types';
import { LavaProvidersRequest } from 'store/api/lava-restake-specs/types';

export const lavaRestakeSpecsApi = createApi({
  baseQuery: cosmosApiQuery,
  reducerPath: 'lavaRestakeSpecsApi',
  endpoints: (build) => ({
    getLavaRestakeProviders: build.query<
      LavaRestakeProviderData[],
      LavaProvidersRequest
    >({
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(URL.LAVA_RESTAKE_PROVIDERS, {
          ...params,
          ...getSortParams(params?.sort),
          size: params?.size || 1000,
        }),
      }),
    }),
  }),
});

export const { useGetLavaRestakeProvidersQuery } = lavaRestakeSpecsApi;
