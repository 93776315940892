import { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';

import { Image } from '../icons';

import * as Styles from './styles';

export const EmptyPlaceholder = ({ children, ...props }: PropsWithChildren) => {
  const { isDarkTheme } = useTheme();
  return (
    <Styles.Container {...props}>
      <Styles.BackgroundImage
        {...(isDarkTheme
          ? Image.PlaceholderBackgroundDark
          : Image.PlaceholderBackgroundLight)}
      />
      <Styles.Background />
      <Styles.Title>{children}</Styles.Title>
    </Styles.Container>
  );
};
