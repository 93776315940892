import { lazy, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Route } from 'react-router-dom';

import { Routes } from 'polli-commons-fe/sentry';
import {
  Suspense,
  ErrorBoundary,
  ProtectedRoute,
} from 'polli-commons-fe/components';

import { useAppSelector } from 'store';
import { AppVersionLabel } from 'app/styles';
import { Routes as RoutePaths } from 'config';
import { selectAuth, selectIsLoggedIn } from 'store/slices/auth';
import {
  Login,
  ResetPassword,
  ChangePassword,
  EmailVerification,
} from 'pages/auth';

import { useAuthEventsConnect } from './use-auth-events-connect';

const appVersion = import.meta.env.VITE_REACT_APP_SENTRY_RELEASE_NUMBER;

const AppPrivate = lazy(() => import('./app-private'));
const GeneralChainOnboarding = lazy(
  () => import('pages/chain-onboarding/general')
);
const TermsAndConditions = lazy(
  () => import('pages/auth/terms-and-conditions')
);

const mappedRoutes = {
  [RoutePaths.Auth.Login + '/*']: Login,
  [RoutePaths.Auth.ForgotPassword]: ResetPassword,
  [RoutePaths.Auth.ChangePassword]: ChangePassword,
  [RoutePaths.WalletSignIn]: GeneralChainOnboarding,
  [RoutePaths.Auth.EmailVerification]: EmailVerification,
  [RoutePaths.Auth.TermsAndConditions]: TermsAndConditions,
};

export function App() {
  const { authUser } = useAppSelector(selectAuth);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  useEffect(() => {
    if (!authUser) {
      Sentry.setUser(null);
      return;
    }
    Sentry.setUser({
      id: authUser.sub,
      email: authUser.email,
    });
  }, [authUser]);

  useAuthEventsConnect();

  return (
    <>
      <Routes>
        {Object.keys(mappedRoutes).map((path) => {
          const Component = mappedRoutes[path];
          return (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRoute
                  allowed={!isLoggedIn}
                  redirect={RoutePaths.Home}
                >
                  <Suspense>
                    <Component />
                  </Suspense>
                </ProtectedRoute>
              }
            />
          );
        })}
        <Route
          path="*"
          element={
            <ProtectedRoute
              allowed={isLoggedIn}
              redirect={RoutePaths.WalletSignIn}
            >
              <Suspense>
                <ErrorBoundary>
                  <AppPrivate />
                </ErrorBoundary>
              </Suspense>
            </ProtectedRoute>
          }
        />
      </Routes>

      {appVersion && <AppVersionLabel>{appVersion}</AppVersionLabel>}
    </>
  );
}
