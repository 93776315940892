import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Icon } from '../../icons';
import { Flex } from '../../styles';
import { gradientBorder } from '../../../styles/mixins';

export const TableRowContainer = styled.div<{ $opened: boolean }>`
  border-radius: 28px;
  min-width: 0;

  ${({ theme, onClick, $opened }) => css`
    padding: ${$opened ? '12px 16px' : '12px 16px 0 16px'};

    ${onClick &&
    css`
      cursor: pointer;

      &:active {
        ${gradientBorder};

        background: ${theme.config.input.background.active};
      }
    `}

    background: ${theme.config.tableRow.background};
    box-shadow: ${theme.config.tableRow.boxShadow};
    backdrop-filter: ${theme.config.tableRow.backdropFilter};
    -webkit-backdrop-filter: ${theme.config.tableRow.backdropFilter};
  `}
`;

export const ValuesContainer = styled(motion.div)`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 8px;

  > * {
    flex-basis: 48%;
    min-width: 0;
  }
`;

export const TopRow = styled(Flex)`
  padding-bottom: 16px;
  align-items: center;
`;

export const ToggleCardIcon = styled(Icon.Plus)<{ $opened: boolean }>`
  ${({ $opened }) => css`
    path:nth-child(2) {
      opacity: 1;
      transition: opacity 300ms;
    }

    ${$opened &&
    css`
      > path:nth-child(2) {
        opacity: 0;
      }
    `}
  `}
`;
