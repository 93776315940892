import { ReactNode } from 'react';

import { ValidatorStatus } from 'polli-commons-fe/types';

import { Icon } from '../icons';

import * as Styles from './styles';

export const offlineStatuses = [
  'ACTIVE_OFFLINE',
  'EXITING_OFFLINE',
  'SLASHING_OFFLINE',
] as const;

export const label: Record<ValidatorStatus, string> = {
  EXITED: 'Exited',
  PENDING: 'Pending',
  SLASHED: 'Slashed',
  DEPOSITED: 'Deposited',
  ACTIVE_ONLINE: 'Active',
  EXITING_ONLINE: 'Exiting',
  SLASHING_ONLINE: 'Slashing',
  DEPOSITED_INVALID: 'Deposit Failed',
  ...(offlineStatuses.reduce<Record<string, string>>((result, status) => {
    result[status] = 'Offline';
    return result;
  }, {}) as Record<(typeof offlineStatuses)[number], 'Offline'>),
};

export const icon: Record<ValidatorStatus, ReactNode> = {
  EXITED: <Styles.ExitedIcon />,
  PENDING: <Styles.PendingIcon />,
  SLASHED: <Styles.SlashedIcon />,
  ACTIVE_OFFLINE: <Icon.XCircle />,
  ACTIVE_ONLINE: <Styles.RSSIcon />,
  EXITING_OFFLINE: <Icon.XCircle />,
  DEPOSITED: <Icon.BankCardEject />,
  SLASHING_OFFLINE: <Icon.XCircle />,
  DEPOSITED_INVALID: <Icon.BankCardEject />,
  EXITING_ONLINE: <Styles.ExitingOnlineIcon />,
  SLASHING_ONLINE: <Styles.SlashingOnlineIcon />,
};

export const description: Record<ValidatorStatus, string> = {
  PENDING: 'Waiting to get activated',
  DEPOSITED_INVALID: 'Deposit has been invalid',
  DEPOSITED: 'Validator has made a deposit and has registered in BeaconState',
  EXITING_OFFLINE:
    'Validator is going to exit (and last 3 attestations are missing)',
  EXITING_ONLINE:
    'Validator is going to exit (and last 3 attestations are successful)',
  EXITED:
    'Validator reached regular exit epoch, not being slashed (punished for bad behaviour)',
  ACTIVE_OFFLINE:
    'Validator is currently active and not attesting (3 last attestations are missing)',
  ACTIVE_ONLINE:
    'Validator is currently active and attesting/working properly (3 last attestations are successful)',
  SLASHED:
    'Validator was slashed/punished for bad behaviour and no longer participates in network because got disconnected/kicked off',
  SLASHING_OFFLINE:
    'Validator is getting slashed and waiting to be disconnected from the network but last 3 attestations are missing)',
  SLASHING_ONLINE:
    'Validator is getting slashed and waiting to be disconnected from the network but last 3 attestations are successful)',
};
