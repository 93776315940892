import styled, { css } from 'styled-components';

import { Flex } from '../styles';
import { gradientBorder, handleTableRowTemplate } from '../../styles/mixins';

export const TableRowContainer = styled.div<{
  $gap: number;
  $padding: string;
  $template: string;
}>`
  display: grid;
  border-radius: 28px;
  align-items: center;

  ${handleTableRowTemplate};

  ${({ $gap, theme, onClick, $padding }) => css`
    grid-gap: ${$gap}px;
    padding: ${$padding};

    ${onClick &&
    css`
      cursor: pointer;

      &:hover {
        ${gradientBorder};

        background: ${theme.config.input.background.hover};
      }

      &:active {
        ${gradientBorder};

        background: ${theme.config.input.background.active};
      }
    `}

    background: ${theme.config.tableRow.background};
    box-shadow: ${theme.config.tableRow.boxShadow};
    backdrop-filter: ${theme.config.tableRow.backdropFilter};
    -webkit-backdrop-filter: ${theme.config.tableRow.backdropFilter};
  `}
`;

export const ItemContainer = styled(Flex)`
  min-width: 0;
  flex-direction: column;
`;
