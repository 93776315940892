import { createApi } from '@reduxjs/toolkit/query/react';

import { cosmosApiQuery } from 'polli-commons-fe/store/query';
import { addParamsToUrl } from 'polli-commons-fe/utils/query';

import { URL } from 'api/constants';

import {
  CosmosMultiValidatorsBalanceHistoryRequest,
  CosmosMultiValidatorsBalanceHistoryResponse,
} from './types';

export const cosmosMultiValidators = createApi({
  baseQuery: cosmosApiQuery,
  reducerPath: 'cosmosMultiValidatorsApi',
  endpoints: (build) => ({
    getCosmosMultiValidatorsBalanceHistory: build.query<
      CosmosMultiValidatorsBalanceHistoryResponse,
      CosmosMultiValidatorsBalanceHistoryRequest
    >({
      query: ({ balanceRange, walletAddress }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.COSMOS_MULTI_VALIDATORS_BALANCE_HISTORY.replace(
            '{walletAddress}',
            walletAddress
          ),
          { balanceRange }
        ),
      }),
    }),
  }),
});

export const { useGetCosmosMultiValidatorsBalanceHistoryQuery } =
  cosmosMultiValidators;

export type {
  CosmosMultiValidatorsBalanceHistoryRequest,
  CosmosMultiValidatorsBalanceHistoryResponse,
};
