import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';

export const optimizeSuggestionChainsSlice = createSlice({
  name: 'optimizeSuggestionChains',
  initialState: null as string[] | null,
  reducers: {
    setOptimizeSuggestionChains: (_, { payload }) => payload,
  },
});

export const {
  actions: { setOptimizeSuggestionChains },
} = optimizeSuggestionChainsSlice;

export const selectOptimizeSuggestionChains = (store: RootState) =>
  store.optimizeSuggestionChains;
