import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Flex } from 'polli-commons-fe/components';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Heading = styled(Flex)`
  padding: 16px;
  justify-content: space-between;
`;

export const ValidatorsTableContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
`;

export const SummaryContainer = styled(Flex)`
  margin-top: auto;
  flex-direction: column;

  ${({ theme }) =>
    theme.responsive.isTabletUp
      ? css`
          padding: 24px 40px;
          gap: 20px;
        `
      : css`
          padding: 24px;
          gap: 8px;
        `}
`;

export const SummaryInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
