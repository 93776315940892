import { css, createGlobalStyle } from 'styled-components';

import { gradientBorder } from 'polli-commons-fe/styles/mixins';
import { GlobalStyles as CoreGlobalStyles } from 'polli-commons-fe/styles/globalStyles';

const GlobalStyles = createGlobalStyle`
  ${CoreGlobalStyles};

  body {
    height: 100vh;

    #root {
      height: 100%;
    }

    ${({ theme }) => css`
      background: ${theme.config.background};
      color: ${theme.config.text.primary.main};
    `}
  }
  
  .__floater__open {
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border-radius: 28px;
    background: ${({ theme }) => theme.config.popup.background.main};
  }
  
  .react-joyride__spotlight {
    ${gradientBorder};
    border-radius: 35px !important;

    &::before {
      width: 100%;
      height: 100%;
      z-index: 0;
      padding: 5px;
      filter: blur(4px);
    }
  }
  
  .recharts-tooltip-wrapper {
    z-index: ${({ theme }) => theme.zLayerOrder.chartTooltipWrapper};
  }
`;

export { GlobalStyles };
