import { ethers } from 'ethers';

import {
  CurrencyToChainType,
  ChainTypeToCurrencyLabel,
} from 'polli-commons-fe/types/data';

import {
  Currency,
  ChainType,
  AmountResponse,
  EthereumChainCurrency,
  CosmosChainTypeCurrency,
  CosmosChainTypeCurrencyOptions,
  EthereumChainTypeCurrencyOptions,
} from '../types';

import { formatNumber, formatSmallNumber } from './helpers';

export const currencyDefaultMinimalValue = 0.00001;

export const formatUSDPrecise = (value: string | number) =>
  `$${formatSmallNumber(value)}`;

export const addBtcLabel = (value: string | number) => `${value} BTC`;

export const formatUSD = (value: string | number) => {
  const num = Number(value);

  if (num < currencyDefaultMinimalValue && num > 0) {
    return `< $${currencyDefaultMinimalValue}`;
  } else if (num < 1 && num > 0) {
    return `$${formatNumber(num)}`;
  } else
    return new Intl.NumberFormat('en-US', {
      currency: 'USD',
      style: 'currency',
    }).format(num);
};

export const addCurrencyLabel = (
  value: string | number,
  chainType: ChainType
) => {
  return `${value} ${ChainTypeToCurrencyLabel[chainType]}`;
};

export const gweiToEtherPrecise = (gwei: string) => {
  if (gwei === '0') return gwei;

  const gweiInWei = ethers.parseUnits(gwei, 9);

  return formatSmallNumber(ethers.formatEther(gweiInWei));
};

export const convertMicroUnitToUnitPrecise = (
  uatom: string,
  decimalPlaces: number = 0
) => {
  if (uatom === '0') return uatom;

  const divided = +uatom / 1_000_000;

  return formatSmallNumber(divided, decimalPlaces);
};

export const convertCosmosUnitToMicroUnit = (unit: number) => unit * 1_000_000;

export const weiToEtherPrecise = (wei: string) => {
  if (wei === '0') return wei;

  const eth = ethers.formatEther(wei);

  return formatSmallNumber(eth);
};

export const baseToMainCurrency = ({ value, currency }: AmountResponse) => {
  if (
    CosmosChainTypeCurrencyOptions.includes(currency as CosmosChainTypeCurrency)
  ) {
    return convertMicroUnitToUnitPrecise(value);
  }

  switch (currency) {
    case 'gwei':
      return gweiToEtherPrecise(value);
    case 'wei':
      return weiToEtherPrecise(value);
    default:
      return `${value} ${currency}`;
  }
};

export const convertAndLabelCurrency = ({
  value,
  currency,
}: AmountResponse) => {
  const converted = baseToMainCurrency({ value, currency });
  return addCurrencyLabel(converted, CurrencyToChainType[currency]);
};

export const getCurrencyFormatter = ({
  price,
  value = 0,
  chainType,
  precise = false,
  currency = 'USD',
}: {
  price?: string;
  precise?: boolean;
  currency?: Currency;
  chainType?: ChainType;
  value?: string | number;
}) => {
  const convertedValue = price ? +value * +price : value;

  switch (currency) {
    case 'USD':
      return precise
        ? formatUSDPrecise(convertedValue)
        : formatUSD(convertedValue);
    case 'ETH':
      return chainType === 'ETHEREUM'
        ? precise
          ? formatUSDPrecise(convertedValue)
          : formatUSD(convertedValue)
        : addCurrencyLabel(
            precise
              ? formatSmallNumber(convertedValue)
              : formatNumber(convertedValue),
            'ETHEREUM'
          );
    case 'BTC':
      return addBtcLabel(
        precise
          ? formatSmallNumber(convertedValue)
          : formatNumber(convertedValue)
      );
  }
};

export const isMainCurrency = (currency: string) =>
  CosmosChainTypeCurrencyOptions.includes(
    currency as CosmosChainTypeCurrency
  ) ||
  EthereumChainTypeCurrencyOptions.includes(currency as EthereumChainCurrency);
