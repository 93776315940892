import { css } from 'styled-components';

export const getAccentGradientText = (color?: string) => css`
  background: ${({ theme }) => color ?? theme.colors.accent.primary.main};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const gradientBorder = css`
  position: relative;

  &::before {
    inset: 0;
    content: '';
    z-index: -1;
    padding: 1px;
    position: absolute;
    border-radius: inherit;
    background: ${({ theme }) => theme.colors.accent.primary.main};
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export const modalBackground = css`
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  ${({ theme }) => css`
    background: ${theme.config.modal.background};
  `};
`;

export const ellipsisText = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const handleTableRowTemplate = ({
  $template,
}: {
  $template: string;
}) => {
  if ($template.includes(' / ')) {
    const [rows, columns] = $template.split(' / ');

    return css`
      grid-template-rows: ${rows};
      grid-template-columns: ${columns};
    `;
  } else {
    return css`
      grid-template-columns: ${$template};
    `;
  }
};

type SideWidth = number;

interface SideGradientBorderProps {
  color?: string;
  sides: {
    top?: SideWidth;
    left?: SideWidth;
    right?: SideWidth;
    bottom?: SideWidth;
  };
}

export const sideGradientBorder = (
  { sides, color }: SideGradientBorderProps = {
    sides: {
      top: 1,
    },
  }
) => css`
  border-image-source: ${({ theme }) =>
    color ?? theme.colors.accent.secondary.main};
  border-image-slice: 1;
  border-image-width: ${sides.top ?? 0} ${sides.right ?? 0} ${sides.bottom ?? 0}
    ${sides.left ?? 0};
  border-style: solid;
`;
