import styled from 'styled-components';

import { Flex } from 'polli-commons-fe/components';
import { CosmosChainType } from 'polli-commons-fe/types';

import { iconBgImage } from './config';

export const Container = styled(Flex)<{
  chainType: CosmosChainType;
}>`
  width: 488px;
  height: 128px;
  align-items: center;
  justify-content: center;
  background: ${({ chainType }) =>
    `url(${iconBgImage[chainType]}) center / cover`};
`;

export const IconContainer = styled.div`
  svg {
    width: 56px;
    height: 56px;
  }
`;
