import { ReactNode } from 'react';

import { Icon } from 'polli-commons-fe/components/icons';
import {
  ChainType,
  CurrencyLabel,
  AmountResponse,
} from 'polli-commons-fe/types';

export const CurrencyToChainType: Record<
  AmountResponse['currency'],
  ChainType
> = {
  ulava: 'LAVA',
  uscrt: 'SECRET',
  wei: 'ETHEREUM',
  uatom: 'COSMOS',
  gwei: 'ETHEREUM',
};

export const ChainTypeToCurrencyLabel: Record<ChainType, CurrencyLabel> = {
  LAVA: 'LAVA',
  SECRET: 'SCRT',
  COSMOS: 'ATOM',
  ETHEREUM: 'ETH',
};

export const ChainTypeIcon: Record<ChainType, ReactNode> = {
  LAVA: <Icon.Lava />,
  SECRET: <Icon.Secret />,
  COSMOS: <Icon.Cosmos />,
  ETHEREUM: <Icon.Ethereum />,
};
