import { ElementType, HTMLAttributes } from 'react';

import * as Styles from './styles';

export interface GradientHeaderProps
  extends HTMLAttributes<HTMLHeadingElement> {
  as?: ElementType;
  styleType?: 'lava' | 'default';
}

export const GradientHeader = ({
  children,
  styleType = 'default',
  ...props
}: GradientHeaderProps) => (
  <Styles.Header {...props}>
    <Styles.Text styleType={styleType}>{children}</Styles.Text>
  </Styles.Header>
);
