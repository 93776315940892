import styled from 'styled-components';

import { getAccentGradientText } from '../../styles/mixins';

import { GradientHeaderProps } from '.';

export const Header = styled.h3``;

export const Text = styled.span<
  Required<Pick<GradientHeaderProps, 'styleType'>>
>`
  ${({ theme, styleType }) =>
    getAccentGradientText(
      styleType === 'lava' ? theme.colors.lavaGradient.secondary : undefined
    )};
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-transform: uppercase;
`;
