import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

const selectAuth = (store: RootState) => store.auth;

const selectAuthUser = createSelector([selectAuth], (store) => store.authUser);

const selectIsLoggedIn = createSelector([selectAuthUser], (store) =>
  Boolean(store?.sub)
);

export { selectAuth, selectAuthUser, selectIsLoggedIn };
