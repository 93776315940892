import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Flex } from '../styles';
import { gradientBorder } from '../../styles/mixins';

export const Overlay = styled(motion.div)`
  inset: 0;
  position: fixed;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  ${({ theme }) => css`
    z-index: ${theme.zLayerOrder.drawerOverlay};
    background-color: ${theme.config.drawer.overlay};
  `}
`;

export const Inner = styled(motion.div)<{ withBorder: boolean }>`
  ${({ theme, withBorder }) => css`
    z-index: ${theme.zLayerOrder.drawerContent};
    background: ${theme.config.drawer.background};

    ${withBorder &&
    css`
      border-radius: 36px 0 0 36px;

      ${gradientBorder};

      &::before {
        padding: 0 0 0 1px;
      }
    `}
  `}

  top: 0;
  right: 0;
  height: 100%;
  position: fixed;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
`;

export const Heading = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
`;
