import TrezorConnect from '@trezor/connect-web';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { handleNewEthAddress } from 'utils/helpers';

interface TrezorState {
  ethAddress: string | null;
}

export const getTrezorEthAddress = createAsyncThunk(
  'trezor/getEthAddress',
  async (_, { dispatch, rejectWithValue }) => {
    const response = await TrezorConnect.ethereumGetAddress({
      path: "m/44'/60'/0'/0/0",
    });

    if (response.success) {
      const address = response.payload.address;

      const { hasError } = await handleNewEthAddress(address, dispatch);

      if (hasError) return null;

      return address;
    } else {
      return rejectWithValue('Trezor connection error');
    }
  }
);

export const trezorSlice = createSlice({
  name: 'trezor',
  initialState: {
    ethAddress: null,
  } as TrezorState,
  reducers: {
    removeTrezorEthAddress: (state) => {
      state.ethAddress = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTrezorEthAddress.fulfilled, (state, action) => {
      state.ethAddress = action.payload;
    });
  },
});

export const {
  reducer: trezorReducer,
  actions: { removeTrezorEthAddress },
} = trezorSlice;

export const selectTrezor = (store: RootState) => store.trezor;
