import styled, { css } from 'styled-components';

import { ChainType } from 'polli-commons-fe/types';
import { Flex } from 'polli-commons-fe/components/styles';

export const Line = styled.span<{
  $hasData?: boolean;
  $chainType?: ChainType;
}>`
  width: 100%;
  height: 1px;

  ${({ theme, $hasData, $chainType }) => css`
    background: ${$hasData && $chainType
      ? theme.config.chainTypeWithLine.line[$chainType]
      : theme.config.chainTypeWithLine.title};
  `}
`;

export const TextContainer = styled(Flex)<{ $hasData?: boolean }>`
  gap: 8px;
  justify-content: center;

  ${({ $hasData }) =>
    !$hasData &&
    css`
      opacity: 0.2;
      height: 24px;
      border-radius: 16px;
    `}
`;

const titlePlaceholder = css`
  border-radius: 16px;
  background: ${({ theme }) => theme.config.chainTypeWithLine.title.text};
`;

export const IconPlaceholder = styled.span`
  width: 24px;
  ${titlePlaceholder};
`;

export const TitlePlaceholder = styled.div`
  flex: 1;
  ${titlePlaceholder};
`;
