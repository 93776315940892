import ReactGA from 'react-ga4';
import { useCallback } from 'react';
import { UaEventOptions } from 'react-ga4/types/ga4';

export const useAnalyticsEventTracker = (
  category: UaEventOptions['category']
) =>
  useCallback(
    (args: Omit<UaEventOptions, 'category'>) =>
      ReactGA.event({ ...args, category }),
    [category]
  );
