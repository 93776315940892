import { useCallback } from 'react';

import { useAppSelector, useAppDispatch } from 'store';
import {
  selectMetamask,
  connectMetamask,
  disconnectMetamask,
} from 'store/slices/metamask';

export const useMetamaskProvider = () => {
  const metamaskState = useAppSelector(selectMetamask);
  const dispatch = useAppDispatch();

  const connect = useCallback(() => {
    dispatch(connectMetamask());
  }, [dispatch]);

  const disconnect = useCallback(() => {
    dispatch(disconnectMetamask());
  }, [dispatch]);

  return {
    connect,
    disconnect,
    ...metamaskState,
  };
};
