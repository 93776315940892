import styled, { css } from 'styled-components';

import { CheckboxProps } from './types';

export const BaseLabel = styled.label<
  Pick<CheckboxProps, 'checked' | 'disabled'>
>`
  gap: 8px;
  display: flex;
  align-items: flex-start;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `}}
`;

export const InputContainer = styled.div<
  Pick<CheckboxProps, 'checked' | 'disabled'>
>`
  width: 24px;
  height: 24px;
  align-items: center;
  display: inline-flex;
  justify-content: center;

  ${({ theme, checked }) => css`
    > svg path {
      fill: ${theme.config.checkbox.mark};
    }

    ${checked
      ? css`
          background: ${theme.colors.accent.primary.main};
        `
      : css`
          border-width: 2px;
          border-style: solid;
          border-color: ${theme.config.checkbox.main};
        `}
  `}
`;

export const Label = styled(BaseLabel)`
  ${({ theme, checked, disabled }) => css`
    ${disabled &&
    css`
      pointer-events: none;
    `}

    ${checked
      ? css`
          &:hover {
            ${InputContainer} {
              background: ${theme.colors.accent.primary.hover};
            }
          }

          &:active {
            ${InputContainer} {
              background: ${theme.colors.accent.primary.active};
            }
          }
        `
      : css`
          &:hover {
            ${InputContainer} {
              border-color: ${theme.config.checkbox.hover};
            }
          }

          &:active {
            ${InputContainer} {
              border-color: ${theme.config.checkbox.active};
            }
          }
        `}
  `}
`;

export const Input = styled.input`
  position: absolute;
  -webkit-appearance: none;
  appearance: none;
`;
