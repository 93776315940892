import { isDesktop } from '../../hooks';

import { Mobile } from './mobile';
import { Desktop } from './desktop';
import { TableRowProps } from './types';
import { TableRowContainer } from './styles';

export const TableRow = ({ headers, mobileProps, ...props }: TableRowProps) => {
  const desktop = isDesktop();

  return desktop ? (
    <Desktop {...props} headers={headers} />
  ) : (
    <Mobile
      {...mobileProps}
      headers={mobileProps?.headers?.map((header) =>
        typeof header === 'object' && header && 'label' in header
          ? header.label
          : header
      )}
      {...props}
    />
  );
};

export { TableRowContainer };
export type { TableRowProps };
