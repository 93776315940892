import { DefaultTheme } from 'styled-components';

import { FONTS } from '../fonts';
import zLayerOrder from '../z-layer-order';

import { DARK_COLORS } from './colors';
import { DARK_CONFIG } from './config';

const DARK_THEME: DefaultTheme = Object.freeze({
  zLayerOrder,
  fonts: FONTS,
  isDarkTheme: true,
  colors: DARK_COLORS,
  config: DARK_CONFIG,
  responsive: {
    isMobile: false,
    isTablet: false,
    isDesktop: true,
    isTabletUp: false,
  },
});

export { DARK_THEME };
