import { ReactNode } from 'react';

import { mapQuery } from 'polli-commons-fe/utils/query';
import { CosmosChainType } from 'polli-commons-fe/types';
import { TableProps } from 'polli-commons-fe/components/table';
import { addCurrencyLabel } from 'polli-commons-fe/utils/currencies';
import { Semibold, TableRowProps } from 'polli-commons-fe/components';
import { useTableSort, useAnalyticsEventTracker } from 'polli-commons-fe/hooks';
import { TableWithSortingProps } from 'polli-commons-fe/components/sortable-table';
import { composeGridTemplateColumns } from 'polli-commons-fe/components/table/helpers';
import {
  formatNumber,
  addPercentageLabel,
} from 'polli-commons-fe/utils/helpers';

import { BaseCurrencyIcon } from 'types/data';
import { ListPopup, ValidatorInfo, VirtualizedList } from 'components';
import { VirtualizedListProps } from 'components/virtualized-list/types';
import {
  formatCosmosUnit,
  convertCosmosMicroUnitToUnit,
} from 'utils/currencies';
import {
  CosmosValidatorData,
  GetCosmosValidatorsResponse,
} from 'store/api/cosmos-validators';

const validatorsListTemplate = composeGridTemplateColumns([
  { fr: 2 },
  { fr: 1, repeat: 4 },
  { fr: 2 },
  { fr: 1, repeat: 2 },
]);

const headers: TableWithSortingProps['headers'] = [
  {
    accessor: 'name',
    label: 'Validator',
  },
  'Reward Currencies',
  {
    label: 'Validator APR',
    accessor: 'validatorAnnualPercentageRate',
  },
  {
    label: 'Network APR',
    accessor: 'networkAnnualPercentageRate',
  },
  {
    label: 'Validator Commission',
    accessor: 'validatorCommissionRate',
  },
  {
    accessor: 'totalStakedTokens',
    label: 'Total Delegated Tokens',
  },
  {
    label: 'Voting Power',
    accessor: 'votingPower',
    info: 'Represents the influence a participant has in the network decision-making process, determined by the amount of cryptocurrency they have staked with validators',
  },
  {
    label: 'Scoring rate',
    accessor: 'scoringRate',
    info: 'Custom indicator that combines factors such as APR, commission rates, and overall voting power to assess their reliability and performance within the network',
  },
];

type ValidatorData = GetCosmosValidatorsResponse['data'][number];

interface ValidatorsTableProps
  extends Omit<ReturnType<typeof useTableSort>, 'setSortState'>,
    TableProps {
  sortingDisabled?: boolean;
  chainType: CosmosChainType;
  additionalTemplate?: string;
  data?: GetCosmosValidatorsResponse;
  additionalHeaders?: TableRowProps['headers'];
  renderTableRow: (props: {
    data: ValidatorData;
    baseRowProps: Pick<TableRowProps, 'values' | 'template'>;
  }) => ReactNode;
}

export const ValidatorsTable = ({
  data,
  sortState,
  chainType,
  emptyTitle,
  handleSorting,
  renderTableRow,
  sortingDisabled,
  additionalTemplate,
  additionalHeaders = [],
  placeholderRowsCount = 9,
  ...validatorsQuery
}: ValidatorsTableProps) => {
  const eventTracker = useAnalyticsEventTracker(
    'Choose validators to delegate'
  );

  const items = data?.data;

  const resultHeaders = [...headers, ...additionalHeaders];
  const resultTemplate = additionalTemplate
    ? `${validatorsListTemplate} ${additionalTemplate}`
    : validatorsListTemplate;

  const rowRenderer: VirtualizedListProps<CosmosValidatorData>['rowRenderer'] =
    (currentItemData) => {
      const baseRowProps = {
        template: resultTemplate,
        values: [
          <ValidatorInfo
            name={currentItemData.name}
            address={currentItemData.address}
          />,
          <ListPopup
            iconMapper={BaseCurrencyIcon}
            data={currentItemData.rewardCurrencies}
          />,
          <Semibold>
            {addPercentageLabel(
              formatNumber(currentItemData.validatorAnnualPercentageRate, 2)
            )}
          </Semibold>,
          addPercentageLabel(
            formatNumber(currentItemData.networkAnnualPercentageRate, 2)
          ),
          addPercentageLabel(currentItemData.validatorCommissionRate),
          addCurrencyLabel(
            formatCosmosUnit(
              convertCosmosMicroUnitToUnit(currentItemData.totalStakedTokens)
            ),
            chainType
          ),
          addPercentageLabel(formatNumber(currentItemData.votingPower, 2)),
          formatNumber(currentItemData.scoringRate, 2),
        ],
      };

      return renderTableRow({ baseRowProps, data: currentItemData });
    };

  return (
    <VirtualizedList
      {...mapQuery(validatorsQuery)}
      data={items}
      sortState={sortState}
      headers={resultHeaders}
      emptyTitle={emptyTitle}
      rowRenderer={rowRenderer}
      template={resultTemplate}
      eventTracker={eventTracker}
      handleSorting={handleSorting}
      sortingDisabled={sortingDisabled}
      placeholderRowsCount={placeholderRowsCount}
    />
  );
};
