import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from '@reduxjs/toolkit';

import { handleError } from 'polli-commons-fe/utils/error';
import { ChainId, CosmosWalletProvider } from 'polli-commons-fe/types';
import { getCosmosWalletAddress } from 'polli-commons-fe/utils/cosmos';

import { RootState } from 'store/store';
import { isWalletAlreadyLinkedError } from 'utils/helpers';
import { cosmosWalletsApi } from 'store/api/cosmos-wallets';
import { ENABLED_COSMOS_NETWORK_CHAIN_TYPES } from 'config/constants';
import { setAlreadyLinkedWallets } from 'store/slices/already-linked-wallets-addresses';

export interface ConnectedCosmosChainState {
  address: string | null;
}

export type ConnectedCosmosChainsState = {
  providerName: CosmosWalletProvider | null;
  chains: {
    [key in ChainId]: ConnectedCosmosChainState;
  };
};

const initialState: ConnectedCosmosChainsState = {
  providerName: null,
  chains: Object.values(ChainId).reduce<ConnectedCosmosChainsState['chains']>(
    (result, chainId) => {
      result[chainId] = {
        address: null,
      };

      return result;
    },
    {} as ConnectedCosmosChainsState['chains']
  ),
};

export const connectCosmosChains = createAsyncThunk(
  'connectedCosmosChains/connectCosmosChain',
  async (providerName: CosmosWalletProvider, { dispatch }) => {
    try {
      const chains: Partial<ConnectedCosmosChainsState['chains']> = {};

      const savedCosmosWalletsPromise = dispatch(
        cosmosWalletsApi.endpoints.getCosmosWallets.initiate()
      );

      const { data: savedCosmosWallets } = await savedCosmosWalletsPromise;

      if (savedCosmosWallets) {
        const alreadyLinkedWallets = [];

        for (const chain of ENABLED_COSMOS_NETWORK_CHAIN_TYPES) {
          const chainId = ChainId[chain];

          const walletAddress = await getCosmosWalletAddress({
            providerName,
            chainType: chain,
          });

          if (!walletAddress) {
            continue;
          }

          const savedWalletsIncludesAddress = savedCosmosWallets?.some(
            ({ wallet }) => wallet.address === walletAddress
          );

          if (!savedWalletsIncludesAddress) {
            const saveCosmosWalletPromise = dispatch(
              cosmosWalletsApi.endpoints.saveCosmosWallet.initiate(
                walletAddress
              )
            );

            const { error } = await saveCosmosWalletPromise;

            if (isWalletAlreadyLinkedError(error)) {
              alreadyLinkedWallets.push(walletAddress);
              continue;
            }
          }

          chains[chainId] = {
            address: walletAddress,
          };
        }

        savedCosmosWalletsPromise.unsubscribe();

        if (alreadyLinkedWallets.length) {
          dispatch(setAlreadyLinkedWallets(alreadyLinkedWallets));

          return;
        }

        return {
          chains,
          providerName,
        };
      }
    } catch (e) {
      handleError(e);
    }
  }
);

export const connectedCosmosChainsSlice = createSlice({
  initialState,
  name: 'connectedCosmosChains',
  reducers: {
    disconnectAllCosmosChains: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(connectCosmosChains.fulfilled, (state, action) => {
      if (action.payload) {
        state.chains = { ...state.chains, ...action.payload.chains };
        state.providerName = action.payload.providerName;
      }
    });
  },
});

export const { disconnectAllCosmosChains } = connectedCosmosChainsSlice.actions;

const selectConnectedCosmosChainsState = (state: RootState) =>
  state.connectedCosmosChains;

export const selectConnectedCosmosChains = createSelector(
  [selectConnectedCosmosChainsState],
  (state) => ({
    ...state,
    chains: ENABLED_COSMOS_NETWORK_CHAIN_TYPES.reduce(
      (result, chainType) => {
        const chainId = ChainId[chainType];

        result[chainId] = state.chains[chainId];

        return result;
      },
      {} as ConnectedCosmosChainsState['chains']
    ),
  })
);

export const selectConnectedCosmosProviderName = createSelector(
  [selectConnectedCosmosChainsState],
  (state) => state.providerName
);
