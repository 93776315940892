import { FlexProps } from 'polli-commons-fe/components/styles';
import {
  Flex,
  EllipsisText,
  CosmosValidatorImage,
} from 'polli-commons-fe/components';

export const ValidatorInfo = ({
  name,
  address,
  ...props
}: {
  name?: string;
  address: string;
} & FlexProps) => {
  return (
    <Flex gap={8} minWidth={0} {...props}>
      <CosmosValidatorImage rounded address={address} />

      <EllipsisText>{name}</EllipsisText>
    </Flex>
  );
};
