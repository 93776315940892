import styled from 'styled-components';

import { Flex } from '../styles';

export const PlaceholderWrapper = styled(Flex)`
  flex: 1;
  position: relative;
  flex-direction: column;
  min-height: 128px;
`;

export const FetchingIconContainer = styled.div`
  svg {
    width: 48px;
    height: 48px;
  }
`;
