import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { CosmosTransactionStatus } from 'types';

export interface TransactionLoaderState {
  isLoading: boolean;
  retries: number | null;
  retriesLimit: number | null;
  status: CosmosTransactionStatus | null;
}

export const transactionLoaderSlice = createSlice({
  name: 'transactionLoader',
  initialState: { status: null, isLoading: false } as TransactionLoaderState,
  reducers: {
    showTransactionLoader: (state) => {
      state.isLoading = true;
    },
    setTransactionRetries: (state, { payload }: PayloadAction<number>) => {
      state.retries = payload;
    },
    setTransactionRetriesLimit: (state, { payload }: PayloadAction<number>) => {
      state.retriesLimit = payload;
    },
    setTransactionStatus: (
      state,
      { payload }: PayloadAction<TransactionLoaderState['status']>
    ) => {
      state.status = payload;
    },
    closeTransactionLoader: (state) => {
      state.isLoading = false;
      state.status = null;
      state.retries = null;
      state.retriesLimit = null;
    },
  },
});

export const {
  reducer: transactionLoaderReducer,
  actions: {
    setTransactionStatus,
    showTransactionLoader,
    setTransactionRetries,
    closeTransactionLoader,
    setTransactionRetriesLimit,
  },
} = transactionLoaderSlice;

export const selectTransactionLoader = (store: RootState) =>
  store?.transactionLoader;
