import { getCosmosValidatorImageSrc } from 'polli-commons-fe/utils/helpers';
import {
  BaseValidatorImage,
  BaseValidatorImageProps,
} from 'polli-commons-fe/components/base-validator-image';

export const CosmosValidatorImage = ({
  address,
  ...props
}: {
  address?: string;
} & BaseValidatorImageProps) => (
  <BaseValidatorImage {...props} src={getCosmosValidatorImageSrc(address)} />
);
