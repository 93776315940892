import { createApi } from '@reduxjs/toolkit/query/react';

import { ethereumApiQuery } from 'polli-commons-fe/store/query';

import { URL } from 'api/constants';

import { WalletsSummarizeResponse, WalletsSummarizeGetRequest } from './types';

export const walletSummariseTagTypes = ['wallets-summarise'];

export const walletsSummariseApi = createApi({
  baseQuery: ethereumApiQuery,
  tagTypes: walletSummariseTagTypes,
  reducerPath: 'walletsSummariseApi',
  endpoints: (build) => ({
    get: build.query<WalletsSummarizeResponse, WalletsSummarizeGetRequest>({
      providesTags: walletSummariseTagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.WALLETS_SUMMARISE,
      }),
    }),
  }),
});

export const { useGetQuery } = walletsSummariseApi;
export type { WalletsSummarizeGetRequest };
