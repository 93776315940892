import { Form as FormikForm } from 'formik';
import styled, { css } from 'styled-components';

import { Flex } from '../styles';
import { Popup as PopupComponent } from '../popup';
import { gradientBorder } from '../../styles/mixins';
import BackgroundStars from '../../assets/background-stars.svg';

export const Popup = styled(PopupComponent)`
  &-content {
    ${gradientBorder};

    padding: 40px !important;

    ${({ theme }) =>
      theme.responsive.isMobile &&
      css`
        padding: 24px !important;
      `}
  }
`;

export const BlurBackground = styled.div`
  inset: 0;
  z-index: -10;
  position: absolute;
  border-radius: 16px;
  background:
    ${({ theme }) => theme.config.transparentBackground},
    url(${BackgroundStars}) no-repeat;
  background-size: contain;
`;

export const Container = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const PopupFooter = styled(Flex)`
  gap: 24px;
  margin-top: auto;

  button {
    flex: 1;
  }

  ${({ theme }) =>
    theme.responsive.isMobile &&
    css`
      flex-direction: column;
    `}
`;

export const Form = styled(FormikForm)`
  gap: 48px;
  display: flex;
  flex-direction: column;

  ${({ theme }) =>
    theme.responsive.isMobile &&
    css`
      gap: 24px;
    `}
`;

export const Title = styled.h2`
  text-align: center;
`;
