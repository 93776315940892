import { useEffect } from 'react';
import { Hub } from 'aws-amplify/utils';
import { useNavigate } from 'react-router-dom';
import { useReadLocalStorage } from 'usehooks-ts';

import { showError } from 'polli-commons-fe/utils/helpers';

import { useUpdateUser } from 'hooks';
import { useAppDispatch } from 'store';
import { Routes as RoutePaths } from 'config';
import { clearAuth } from 'store/slices/auth';
import { initializeWalkthrough } from 'store/slices/walkthrough';

export const useAuthEventsConnect = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const twoFactorSkipped = useReadLocalStorage('twoFactorSkipped');

  const updateUser = useUpdateUser();

  useEffect(() => {
    const update = async () => {
      try {
        await updateUser();
      } catch (error) {
        console.error(error);
      }
    };

    update();
  }, [updateUser]);

  useEffect(() => {
    const hubListenerCancelToken = Hub.listen('auth', async (data) => {
      const event = data?.payload?.event;

      switch (event) {
        case 'signedOut':
          dispatch(clearAuth());
          break;
        case 'tokenRefresh_failure':
        case 'signInWithRedirect_failure':
          dispatch(clearAuth());
          showError(data?.payload?.data?.error?.message);
          break;
        case 'signedIn':
        case 'signInWithRedirect':
          const { preferred } = await updateUser();
          if (preferred !== 'TOTP' && !twoFactorSkipped) {
            navigate(RoutePaths.Auth.Verification2FA);
          }

          dispatch(initializeWalkthrough());

          break;
        default:
          await updateUser();
      }
    });

    return hubListenerCancelToken;
  }, [dispatch, navigate, updateUser, twoFactorSkipped]);
};
