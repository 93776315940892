import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateUserAttribute, fetchUserAttributes } from 'aws-amplify/auth';

import { RootState } from 'store';

interface Walkthrough {
  run: boolean;
  stepIndex: number;
}

const initialState: Walkthrough = {
  run: false,
  stepIndex: 0,
};

const userWalkthroughAttributeKey = 'custom:walkthroughCompleted';

export const initializeWalkthrough = createAsyncThunk(
  'walkthrough/init',
  async (_, { rejectWithValue }) => {
    try {
      const userAttributes = await fetchUserAttributes();
      return JSON.parse(userAttributes[userWalkthroughAttributeKey] ?? 'false');
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const finishWalkthrough = createAsyncThunk(
  'walkthrough/finish',
  async (_, { rejectWithValue }) => {
    try {
      await updateUserAttribute({
        userAttribute: {
          value: 'true',
          attributeKey: userWalkthroughAttributeKey,
        },
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const walkthroughSlice = createSlice({
  initialState,
  name: 'walkthrough',
  reducers: {
    startWalkthrough: (state) => {
      state.run = true;
    },
    nextWalkthroughStep: (state) => {
      state.stepIndex++;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(finishWalkthrough.fulfilled, (state) => {
      state.run = false;
      state.stepIndex = 0;
    });
    builder.addCase(initializeWalkthrough.fulfilled, (state, { payload }) => {
      state.run = !payload;
    });
  },
});

export const {
  reducer: walkthroughReducer,
  actions: { startWalkthrough, nextWalkthroughStep },
} = walkthroughSlice;

export const selectWalkthrough = (store: RootState) => store.walkthrough;

export const selectWalkthroughInProgress = (store: RootState) =>
  store.walkthrough.run;
