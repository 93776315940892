const darkThemeKey = 'isDarkTheme';

export const saveDarkTheme = (isDarkTheme?: boolean) => {
  if (typeof isDarkTheme === 'boolean') {
    localStorage.setItem(darkThemeKey, String(isDarkTheme));
  } else {
    localStorage.removeItem(darkThemeKey);
  }
};

export const getIsDarkTheme = (
  isThemesEnabled: Boolean = true
): boolean | undefined => {
  if (!isThemesEnabled) {
    localStorage.removeItem(darkThemeKey);
    return true;
  }
  const isDarkTheme = localStorage.getItem(darkThemeKey);
  if (isDarkTheme === null) return undefined;
  return isDarkTheme === 'true';
};
