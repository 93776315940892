import { css } from 'styled-components';
import { normalize } from 'styled-normalize';

import { hero, body, caption, subTitle } from './mixins/fonts';

const GlobalStyles = css`
  ${normalize};

  :root {
    --toastify-color-success: ${({ theme }) => theme.colors.success};
    --toastify-color-error: ${({ theme }) => theme.colors.red.primary.main};
    --toastify-color-dark: ${({ theme }) =>
      theme.config.walletsSidebar.background.delegate};
    --toastify-color-light: ${({ theme }) =>
      theme.config.walletsSidebar.background.delegate};
    --toastify-text-color-dark: ${({ theme }) =>
      theme.config.text.primary.main};
    --toastify-text-color-light: ${({ theme }) =>
      theme.config.text.primary.main};
  }

  * {
    ${body};
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter Variable', sans-serif;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.config.scrollbar.thumb};
  }

  a {
    outline: none;
    text-decoration: none;

    &:visited {
      color: inherit;
    }
  }

  ul {
    list-style-type: none;
  }

  button {
    border: none;
    color: inherit;
    cursor: pointer;
    line-height: inherit;
    background-color: transparent;
  }

  input {
    border: none;
    color: inherit;

    :focus {
      outline: none;
    }
  }

  textarea {
    border: none;
    color: inherit;
    background-color: inherit;
  }

  h1 {
    ${hero};
    margin: 0;
    font-weight: 600;
  }

  h2 {
    ${subTitle};
    font-weight: 600;
  }

  h3 {
    font-weight: 600;
  }

  h4 {
    font-weight: 600;
  }

  h6 {
    ${caption};
  }

  b {
    font-weight: 600;
  }

  svg {
    flex-shrink: 0;
  }
`;

export { GlobalStyles };
