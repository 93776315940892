import { useState } from 'react';

import { Icon } from '../icons';
import { Flex } from '../styles';
import { Button } from '../button';

import * as Styles from './styles';
import { Label, Error } from './styles';
import { InputProps, InputBaseProps, InputPhoneProps } from './types';

export const isBaseInputProps = (
  props: Partial<InputProps>
): props is InputBaseProps => props.type !== 'tel';

export const isPhoneNumberProps = (
  props: Partial<InputProps>
): props is InputPhoneProps => !isBaseInputProps(props);

const RightSlot = ({
  value,
  children,
}: { children: InputProps['rightSlot'] } & Pick<InputProps, 'value'>) =>
  typeof children === 'function' ? children(value) : children;

export const Input = ({
  label,
  error,
  onBlur,
  onFocus,
  leftIcon,
  rightSlot,
  className,
  inputStyle,
  number = false,
  decimal = false,
  disabled = false,
  inputContainerStyle,
  ...props
}: InputProps) => {
  const [inputFocused, setInputFocused] = useState(false);

  const { type = 'text' } = props;

  const [inputType, setInputType] = useState<InputProps['type']>(type);

  return (
    <Flex column className={className}>
      {typeof label === 'string' ? (
        <Label $hasValue={Boolean(props.value)}>{label}</Label>
      ) : (
        label
      )}
      <Styles.InputContainer
        disabled={disabled}
        $hasError={Boolean(error)}
        style={inputContainerStyle}
        $inputFocused={inputFocused}
      >
        {leftIcon && (
          <Styles.LeftIconWrapper>{leftIcon}</Styles.LeftIconWrapper>
        )}

        {isBaseInputProps(props) && (
          <Styles.Input
            style={inputStyle}
            hasLeftIcon={Boolean(leftIcon)}
            onBlur={(e) => {
              setInputFocused(false);
              onBlur?.(e);
            }}
            onFocus={(e) => {
              setInputFocused(true);
              onFocus?.(e);
            }}
            {...props}
            type={inputType}
            onChange={(e) => {
              if (number) {
                let digits;

                if (decimal) {
                  digits = e.target.value.replace(
                    /([^.]+\.?)(.*)/,
                    (_, predicate, dec) =>
                      predicate.replace(/[^\d.]/g, '') + dec.replace(/\D/g, '')
                  );
                } else {
                  digits = e.target.value.replace(/\D/g, '');
                }

                e.target.value = digits;
              }

              props.onChange?.(e);
            }}
          />
        )}

        {isPhoneNumberProps(props) && (
          <Styles.PhoneInput
            country="US"
            style={inputStyle}
            hasLeftIcon={Boolean(leftIcon)}
            onBlur={(e: any) => {
              setInputFocused(false);
              onBlur?.(e);
            }}
            onFocus={(e: any) => {
              setInputFocused(true);
              onFocus?.(e);
            }}
            {...props}
            type={inputType}
          />
        )}

        {(rightSlot || type === 'password') && (
          <Styles.RightSlotWrapper gap={8}>
            <RightSlot value={props.value}>{rightSlot}</RightSlot>

            {type === 'password' && (
              <Button
                styleType="text"
                onClick={() =>
                  setInputType(inputType === 'password' ? 'text' : 'password')
                }
                icon={
                  inputType === 'password' ? (
                    props.value ? (
                      <Icon.Eye />
                    ) : (
                      <Styles.EyeGrey />
                    )
                  ) : props.value ? (
                    <Icon.EyeOff />
                  ) : (
                    <Styles.EyeOffGrey />
                  )
                }
              />
            )}
          </Styles.RightSlotWrapper>
        )}
      </Styles.InputContainer>

      {error && <Error>{error}</Error>}
    </Flex>
  );
};

Input.RightSlot = RightSlot;

export { Label as InputLabel, Error as InputError };
export type { InputProps, InputBaseProps, InputPhoneProps };
