import { useRef, Children, useState, useEffect } from 'react';

import { Tabs } from '../tabs';
import { Input } from '../input';
import { isDesktop } from '../../hooks';
import { GradientHeader } from '../gradient-header';
import { DataFetchWrapper } from '../data-fetch-wrapper';

import * as Styles from './styles';
import { TableProps } from './types';
import { HeadersRow } from './headers-row';
import { PlaceholderTableRow } from './placeholder-table-row';
import { generateTemplate, getTreeBranchesPositions } from './helpers';
import {
  TABLE_TOP_ID,
  childrenContainerGap,
  defaultPlaceholderRowsCount,
  collapsedPlaceholderRowsCount,
} from './config';

export const Table = <TabValue extends string = string>({
  tab,
  tabs,
  title,
  setTab,
  isError,
  headers,
  refetch,
  template,
  children,
  size = 10,
  tabsProps,
  isFetching,
  totalCount,
  currentPage,
  searchQuery,
  columnsCount,
  fetchingIcon,
  maxItemsToShow,
  setCurrentPage,
  setSearchQuery,
  headersRowStyle,
  fetchingMessage,
  searchInputProps,
  itemsContainerStyle,
  placeholderRowsCount,
  viewType = 'default',
  itemsGap = childrenContainerGap,
  emptyTitle = 'You have no data yet',
  ...props
}: TableProps<TabValue>) => {
  const [singleChildHeight, setSingleChildHeight] = useState(0);
  const [childrenContainerHeight, setChildrenContainerHeight] = useState(0);
  const childrenContainerRef = useRef<HTMLDivElement>(null);
  const desktop = isDesktop();

  const actualChildrenCount = Children.count(children);
  const hasChildren = actualChildrenCount > 0;
  const resultPlaceholderRowsCount =
    (currentPage ? size : undefined) ||
    placeholderRowsCount ||
    (viewType !== 'collapsed'
      ? defaultPlaceholderRowsCount
      : collapsedPlaceholderRowsCount);

  const singleChildHeightWithGap = singleChildHeight + itemsGap;
  const childrenContainerMaxHeight =
    maxItemsToShow && singleChildHeightWithGap * maxItemsToShow;

  const treeBranchesCount = actualChildrenCount - 1;
  const treeBranchesPositions = getTreeBranchesPositions(
    singleChildHeight,
    treeBranchesCount
  );

  const resultTemplate =
    template || generateTemplate(headers?.length || columnsCount || 6);

  useEffect(() => {
    if (childrenContainerRef.current) {
      const firstChild = childrenContainerRef.current
        ?.firstChild as HTMLElement;
      const rect = firstChild.getBoundingClientRect();

      if (rect.height) {
        setSingleChildHeight(rect.height);
      }
    }
  }, [childrenContainerRef, setSingleChildHeight, children]);

  useEffect(() => {
    if (childrenContainerRef.current) {
      setChildrenContainerHeight(childrenContainerRef.current.scrollHeight);
    }
  }, [setChildrenContainerHeight, children]);

  return (
    <Styles.Container {...props}>
      {typeof title === 'string' ? (
        <GradientHeader>{title}</GradientHeader>
      ) : (
        title
      )}

      {tabs && tab && setTab && (
        <Tabs
          tabs={tabs}
          selectedTab={tab}
          layoutId="table-tabs"
          setSelectedTab={setTab}
          {...tabsProps}
        />
      )}

      {setSearchQuery && (
        <Input
          type="text"
          value={searchQuery}
          placeholder="Search"
          onChange={(e) => setSearchQuery(e.target.value)}
          {...searchInputProps}
        />
      )}

      <Styles.ItemsContainer
        $viewType={viewType}
        $hasChildren={hasChildren}
        style={itemsContainerStyle}
      >
        {headers && desktop && (
          <HeadersRow
            headers={headers}
            viewType={viewType}
            style={headersRowStyle}
            template={resultTemplate}
          />
        )}

        <Styles.ChildrenWrapper $viewType={viewType}>
          <span id={TABLE_TOP_ID} />

          {viewType === 'tree' &&
            hasChildren &&
            childrenContainerRef.current &&
            !isFetching && (
              <Styles.TreeLine
                $height={childrenContainerHeight - singleChildHeight / 2}
              >
                {Array.from({ length: treeBranchesCount }).map((_, index) => (
                  <Styles.TreeBranch
                    key={index}
                    $position={treeBranchesPositions[index]}
                  />
                ))}
              </Styles.TreeLine>
            )}

          <Styles.ChildrenContainer
            $viewType={viewType}
            $itemsGap={itemsGap}
            $hasChildren={hasChildren}
            ref={childrenContainerRef}
            $maxHeight={childrenContainerMaxHeight}
          >
            <DataFetchWrapper
              isError={isError}
              refetch={refetch}
              hasData={hasChildren}
              isFetching={isFetching}
              emptyTitle={emptyTitle}
              fetchingIcon={fetchingIcon}
              fetchingMessage={fetchingMessage}
              placeholderElement={
                desktop &&
                Array.from({
                  length: resultPlaceholderRowsCount,
                }).map((_, index) => (
                  <PlaceholderTableRow
                    key={index}
                    index={index}
                    viewType={viewType}
                    columnsCount={headers?.length || columnsCount}
                  />
                ))
              }
            >
              {children}
            </DataFetchWrapper>
          </Styles.ChildrenContainer>
        </Styles.ChildrenWrapper>

        {currentPage && setCurrentPage && totalCount && (
          <Styles.Pagination
            size={size}
            isError={isError}
            totalCount={totalCount}
            isFetching={isFetching}
            currentPage={+currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </Styles.ItemsContainer>
    </Styles.Container>
  );
};

export type { TableProps };
