import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

export const alreadyLinkedWalletAddressesSlice = createSlice({
  name: 'alreadyLinkedWalletAddresses',
  initialState: null as string[] | null,
  reducers: {
    setAlreadyLinkedWallets: (_, { payload }: PayloadAction<string[] | null>) =>
      payload,
  },
});

export const {
  actions: { setAlreadyLinkedWallets },
} = alreadyLinkedWalletAddressesSlice;

export const selectAlreadyLinkedWalletAddresses = (store: RootState) =>
  store.alreadyLinkedWalletAddresses;
